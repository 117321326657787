import { Stack, StackProps } from "tamagui";
import { Dimensions } from "react-native";
import { BottomTabBarHeightContext } from "@react-navigation/bottom-tabs";

export default function FullScreenLayout(props: StackProps) {
  const screenHeight = Dimensions.get("window").height || 0;

  return (
    <BottomTabBarHeightContext.Consumer>
      {(tabBarHeight) => (
        <Stack
          flexDirection="column"
          backgroundColor="white"
          alignItems="center"
          minHeight={screenHeight - (tabBarHeight || 0)}
          flex={1}
          {...props}
        />
      )}
    </BottomTabBarHeightContext.Consumer>
  );
}
