import { useEffect, useRef } from "react";
import { Animated, ViewProps } from "react-native";

interface IProps {
  children: React.ReactNode;
  style?: ViewProps;
  fadeProps?: Partial<Animated.TimingAnimationConfig>;
}

export default function FadeInView({ children, style, fadeProps }: IProps) {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
      ...fadeProps,
    }).start();
  }, [fadeAnim]);

  return (
    <Animated.View
      style={{
        ...style,
        opacity: fadeAnim, // Bind opacity to animated value
      }}
    >
      {children}
    </Animated.View>
  );
}
