import { createListenerMiddleware } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api-slice";

const recipeInstructionListenerMiddleware = createListenerMiddleware();
recipeInstructionListenerMiddleware.startListening({
  // Assuming generateRecipeInstructions is the name of your mutation
  matcher: apiSlice.endpoints.generateRecipeInstructions.matchFulfilled,
  effect: async (action, listenerApi) => {
    // Extract the recipe ID from the action payload
    const recipeId = action.meta.arg.originalArgs.id;

    if (recipeId) {
      // Trigger the refetching of getRecipe
      await listenerApi.dispatch(
        apiSlice.endpoints.getRecipe.initiate(
          { recipeId },
          { forceRefetch: true }
        )
      );
    }
  },
});

export { recipeInstructionListenerMiddleware };
