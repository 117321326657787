import { configureStore } from "@reduxjs/toolkit";
import reduxConstants from "./reduxConstants";

// import all your reducers:
import { apiSlice } from "./api/api-slice";
import authReducer from "./features/auth-slice";
import ingredientsReducer from "./features/ingredients-slice";
import recipeReducer from "./features/recipe-slice";
import userReducer from "./features/user-slice";
import { recipeInstructionListenerMiddleware } from "./listenerMiddlewares/recipeListenerMiddleware";

export const store = configureStore({
  reducer: {
    [reduxConstants.AUTH.NAME]: authReducer,
    [reduxConstants.INGREDIENTS.NAME]: ingredientsReducer,
    [reduxConstants.RECIPE.NAME]: recipeReducer,
    [reduxConstants.USER.NAME]: userReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return (
      getDefaultMiddleware()
        // extend default middleware:
        .concat(apiSlice.middleware)
        .concat(recipeInstructionListenerMiddleware.middleware)
    );
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
