import { Stack, XStack, YStack } from "tamagui";
import { UIText } from "../../components/UIText";
import { FlatList, TouchableOpacity } from "react-native";
import PreviousPromptsCard from "./PreviousPromptsCard";
import { useGetPromptsQuery } from "../../redux/api/api-slice";
import { useNavigation } from "@react-navigation/native";

export default function PreviousPrompts({ maxResults = 3 }) {
  const { navigate } = useNavigation();
  const {
    isLoading, // TODO: handle
    data: prompts = [],
    error, // TODO: handle
  } = useGetPromptsQuery();

  const onNavigateToPromptHistory = () => {
    navigate("PromptHistory");
  };

  const last3Prompts =
    typeof maxResults === "number" && !isNaN(maxResults) && maxResults > 0
      ? prompts.slice(0, maxResults)
      : prompts;

  return (
    <YStack width="100%" gap={18}>
      <UIText fontSize={20}>My Feed</UIText>
      <XStack width="100%" justifyContent="space-between" alignItems="end">
        <UIText fontSize={17}>Previous Prompts</UIText>
        {!!prompts?.length && (
          <TouchableOpacity onPress={onNavigateToPromptHistory}>
            <UIText textDecorationLine="underline" color="#4D4C4C">
              See All
            </UIText>
          </TouchableOpacity>
        )}
      </XStack>
      <FlatList
        data={last3Prompts}
        renderItem={({ item, index }) => {
          const description = `${item.meal_type}, incl. ${
            item.ingredients.split(",").length
          } ingredients, using ${
            item.cooking_method.split("(")[0]
          }, for 1 serving${
            item.additional_comments ? `, ${item.additional_comments}` : ""
          }`;
          return (
            <PreviousPromptsCard
              id={item.id}
              number={index + 1}
              date={item.created_at}
              description={description}
            />
          );
        }}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={
          <Stack borderBottomWidth={1} borderColor="#EBEAEA" />
        }
      />
    </YStack>
  );
}
