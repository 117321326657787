import { Circle, Stack, XStack, YStack } from "tamagui";
import { UIText } from "../../components/UIText";
import { Image } from "expo-image";
import { Dimensions } from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import forkAndSpoon from "../../../assets/images/fork-spoon.png";

function InfoPill(props) {
  return (
    <UIText
      fontSize={12}
      backgroundColor="#EDEDED"
      borderRadius={10}
      px={15}
      py={6}
      zIndex={10}
      position="absolute"
      {...props}
    />
  );
}

export default function SharedMealsCard({
  recipe,
  showChef = "true",
  imageContainerStyle = {},
  cardStyle = {},
}) {
  const { user, rating, made } = recipe;
  const screenWidth = Dimensions.get("window").width || 0;
  const imageHeight = (280 / 390) * screenWidth; // math based on Figma image height (280) and screen width (390)
  const { navigate } = useNavigation();

  //changed from Recipe to MealDetails
  const navigateToDetailScreen = () => {
    navigate("MealDetails", { recipe });
  };

  let ingredientCount = 0;
  if (recipe && typeof recipe.ingredients === "string") {
    const splitIngredients = recipe.ingredients
      .split(",")
      .map((ingredient) => ingredient.trim());
    ingredientCount = splitIngredients.length;
    // Now you can work with splitIngredients
  } else if (Array.isArray(recipe?.ingredients)) {
    ingredientCount = recipe.ingredients.length;
  } else {
    // Handle the case where recipe or recipe.ingredients is not valid
    // console.error("Recipe or ingredients is not valid", { recipe });
  }

  return (
    <YStack
      gap={6}
      py={16}
      paddingRight={16}
      position="relative"
      pressStyle={{ opacity: 0.5 }}
      onPress={navigateToDetailScreen}
      {...cardStyle}
    >
      <Stack height={imageHeight} position="relative" {...imageContainerStyle}>
        <Image
          style={{
            height: imageHeight,
            width: "100%",
            backgroundColor: "#EFEFEF",
            borderRadius: 10,
            position: "absolute",
          }}
          source={{ uri: recipe.image }}
          placeholder={recipe.image ? undefined : forkAndSpoon}
          placeholderContentFit="scale-down"
          contentFit="cover"
          transition={1000}
        />

        <InfoPill top={16} left={16}>
          {recipe.duration}
        </InfoPill>
        <InfoPill bottom={16} right={16}>
          {ingredientCount} Ingredients
        </InfoPill>
      </Stack>
      <XStack justifyContent="space-between" alignItems="center">
        <UIText>★ {Number(rating).toFixed(1)}</UIText>
        {!!recipe.made && (
          <XStack gap={2} alignItems="center">
            <Ionicons name="checkmark-outline" color="#629F6C" size={14} />
            <UIText fontSize={11} fontWeight={700} color="#629F6C">
              Made
            </UIText>
          </XStack>
        )}
      </XStack>
      <UIText fontSize={17}>{recipe.name}</UIText>
      {showChef && (
        <XStack alignItems="center" gap={12}>
          {user?.img ? (
            <Image
              style={{
                height: 40,
                width: 40,
                backgroundColor: "#EFEFEF",
                borderRadius: 40,
              }}
              source={{ uri: user?.img }}
              // placeholder={blurhash}
              contentFit="cover"
              transition={1000}
            />
          ) : (
            <Circle backgroundColor="#EFEFEF" width={40} height={40}>
              <Ionicons name="person-outline" size={20} />
            </Circle>
          )}
          <UIText>{user?.username || "[@username]"}</UIText>
        </XStack>
      )}
    </YStack>
  );
}
