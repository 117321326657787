import FullScreenLayout from "../../layouts/FullScreenLayout";
import {
  Checkbox,
  Input,
  Label,
  ScrollView,
  Separator,
  XStack,
  YStack,
} from "tamagui";
import { UIText } from "../../components";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useState } from "react";
import { FlatList } from "react-native";
import { debounce, isErrorResponse } from "../../utility/generalFunctions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useSubmitShelfMutation } from "../../redux/api/api-slice";
import { removeFromShoppingList } from "../../redux/features/ingredients-slice";

export default function ShoppingCartScreen({ navigation }) {
  const shoppingList = useAppSelector(
    (state) => state.ingredients.shoppingList
  );
  const dispatch = useAppDispatch();
  const [selectedIngredientsList, setSelectedIngredientsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [submitToShelf] = useSubmitShelfMutation();
  const onClearAll = () => {
    setSelectedIngredientsList([]);
  };

  const onMarkAll = () => {
    setSelectedIngredientsList(shoppingList);
  };

  const onAddToMyShelf = async () => {
    try {
      const response = await submitToShelf({
        ingredients: selectedIngredientsList,
      });
      if (isErrorResponse(response)) {
        throw response.error;
      }
      selectedIngredientsList.forEach((selectedIngredient) => {
        dispatch(removeFromShoppingList(selectedIngredient));
      });
      navigation.navigate("Ingredients", {
        toTab: "shelf",
      });
    } catch (error) {
      // TODO: show message in Toast
      console.error(error);
    }
  };

  const onSeachIngredient = (val) => {
    setSearchTerm(val);
  };

  return (
    <>
      <FullScreenLayout
        flex={1}
        alignItems="start"
        paddingTop={24}
        px={16}
        width="100%"
        minHeight={undefined}
        overflow="hidden"
      >
        <XStack
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          marginBottom={20}
          minHeight={25}
        >
          <UIText fontSize={21} fontWeight={500}>
            Shopping Cart
          </UIText>
        </XStack>
        <XStack
          alignItems="center"
          borderRadius={6}
          borderWidth={1}
          color="#B0B0B0"
          px={16}
          py={8}
          width="100%"
        >
          <Ionicons color="#B0B0B0" name="search" size={18} />
          <Input
            borderWidth={0}
            focusStyle={{ outlineStyle: "none" }}
            minHeight={14}
            placeholder="Search"
            placeholderTextColor="#B0B0B0"
            size="$4"
            width="100%"
            onChangeText={debounce(onSeachIngredient, 1200)}
          />
        </XStack>

        <XStack
          width="100%"
          justifyContent="space-around"
          paddingBottom={12}
          paddingTop={24}
        >
          <YStack
            alignItems="center"
            pressStyle={{ opacity: 0.5 }}
            onPress={onMarkAll}
          >
            <Ionicons name="checkmark" size={20} />
            <UIText>Mark All</UIText>
          </YStack>
          <YStack
            alignItems="center"
            pressStyle={{ opacity: 0.5 }}
            onPress={onClearAll}
          >
            <Ionicons name="close" size={20} />
            <UIText>Clear all</UIText>
          </YStack>
        </XStack>
        <Separator borderColor="#D9D9D9" width="100%" />
        <ScrollView
          backgroundColor="white"
          paddingBottom={selectedIngredientsList.length ? 50 : 0}
          width="100%"
        >
          <YStack py={24} width="100%">
            <UIText color="#4D4C4C" fontSize={12} marginBottom={16}>
              {shoppingList.length} Ingredients
            </UIText>
            <YStack width="100%" gap={8}>
              <FlatList
                data={shoppingList.filter((ingredient) => {
                  return (
                    !searchTerm ||
                    ingredient.toLowerCase().includes(searchTerm.toLowerCase())
                  );
                })}
                renderItem={({ item }) => {
                  const isChecked = !!selectedIngredientsList.find(
                    (ingredient) => ingredient === item
                  );
                  return (
                    <XStack gap={12} py={16}>
                      <Checkbox
                        id={item}
                        backgroundColor="transparent"
                        checked={isChecked}
                        onCheckedChange={() => {
                          if (!isChecked) {
                            setSelectedIngredientsList((prev) =>
                              prev.concat(item)
                            );
                          } else {
                            setSelectedIngredientsList((prev) =>
                              prev.filter(
                                (_, i) =>
                                  i !==
                                  prev.findIndex(
                                    (ingredient) =>
                                      ingredient === item
                                  )
                              )
                            );
                          }
                        }}
                        height={24}
                        width={24}
                      >
                        <Checkbox.Indicator>
                          <Ionicons name="checkmark" size={20} />
                        </Checkbox.Indicator>
                      </Checkbox>
                      <Label fontWeight="400" htmlFor={item}>
                        {item}
                      </Label>
                    </XStack>
                  );
                }}
                keyExtractor={(item) => item}
                ItemSeparatorComponent={
                  <Separator borderBottomWidth={1} borderColor="#EBEAEA" />
                }
              />
            </YStack>
          </YStack>
        </ScrollView>
      </FullScreenLayout>
      {!!selectedIngredientsList.length && (
        <XStack
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          px={16}
          py={14}
          backgroundColor="white"
        >
          <XStack
            alignItems="center"
            backgroundColor="#000"
            height={44}
            justifyContent="center"
            width="100%"
            pressStyle={{ opacity: 0.5 }}
            onPress={onAddToMyShelf}
          >
            <UIText color="#FFF" fontWeight={600}>
              Add {selectedIngredientsList.length} to My Shelf
            </UIText>
          </XStack>
        </XStack>
      )}
    </>
  );
}
