import { FlatList, ScrollView } from "react-native";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { UIText } from "../../components";
import { Input, Separator, XStack } from "tamagui";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addToShoppingList, removeFromShoppingList } from "../../redux/features/ingredients-slice";

export default function AddToShoppingCartScreen({ navigation }) {
  const ingredients = useAppSelector(state => state.ingredients.shoppingList);
  const dispatch = useAppDispatch();

  const [ingredient, setIngredient] = useState("");

  const addToShoppingCartList = () => {
    dispatch(addToShoppingList([ingredient]));
    setIngredient("");
  };

  const removeFromShoppingCartList = (rowIngredient: string) => {
    dispatch(removeFromShoppingList(rowIngredient));
  };

  const onSubmit = async () => {
    try {
    
      navigation.navigate("Ingredients", {
        toTab: "shop",
      });
    } catch (error) {
      // TODO: show message in Toast
      console.error(error);
    }
  };

  return (
    <FullScreenLayout
      flex={1}
      alignItems="start"
      paddingTop={24}
      px={16}
      width="100%"
      minHeight={undefined}
      overflow="hidden"
    >
      <XStack
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        marginBottom={20}
        minHeight={25}
      >
        <UIText fontSize={21} fontWeight={500}>
          Shopping Cart List
        </UIText>
      </XStack>
      <UIText fontSize={14} fontWeight={"400"}>
        Input the ingredient name in the field below and press ‘Enter’ to add.
      </UIText>

      <XStack>
        <XStack
          alignItems="center"
          borderRadius={6}
          borderWidth={1}
          color="#B0B0B0"
          px={16}
          marginTop={16}
          py={8}
          width="100%"
        >
          <Ionicons color="#B0B0B0" name="add-outline" size={18} />
          <Input
            borderWidth={0}
            focusStyle={{ outlineStyle: "none" }}
            minHeight={14}
            placeholder="e.g Lentils"
            placeholderTextColor="#B0B0B0"
            value={ingredient}
            size="$4"
            width="100%"
            onChangeText={(text) => setIngredient(text)}
            // onChangeText={debounce(onSeachIngredient, 1200)}
          />
        </XStack>
        <UIText
          onPress={addToShoppingCartList}
          px={4}
          marginTop={16}
          py={8}
          fontSize={14}
          fontWeight={"600"}
        >
          Enter
        </UIText>
      </XStack>
      <Separator marginVertical={8} borderWidth={1} color={"#D9D9D9"} />
      <UIText fontSize={12} fontWeight={"400"}>
        {ingredients.length} Ingredients added
      </UIText>

      <FlatList
        data={ingredients}
        renderItem={({ item, index }) => (
          <XStack
            borderBottomWidth={1}
            borderColor={1}
            width={"100%"}
            padding={8}
          >
            <UIText fontSize={17} color={"#000"}>
              {item}
            </UIText>
            <Ionicons
              onPress={() => removeFromShoppingCartList(item)}
              color="#000"
              name="close-outline"
              size={20}
            />
          </XStack>
        )}
        keyExtractor={(item, index) => index.toString()}
      />

      {!!ingredients.length && (
        <XStack
          position="absolute"
          bottom={0}
          borderRadius={4}
          left={0}
          right={0}
          px={16}
          py={14}
          backgroundColor="white"
        >
          <XStack
            alignItems="center"
            backgroundColor="#000"
            height={44}
            justifyContent="center"
            width="100%"
            pressStyle={{ opacity: 0.5 }}
            onPress={onSubmit}
          >
            <UIText color="#FFF">
              Add {ingredients.length} to My Shopping Cart
            </UIText>
          </XStack>
        </XStack>
      )}
    </FullScreenLayout>
  );
}
