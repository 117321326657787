import { Circle, XStack, YStack, Button, Separator, View } from "tamagui";
import { UIText } from "../../components";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import FeatherIcons from "@expo/vector-icons/Feather";
import {
  Feather,
  Ionicons,
  MaterialCommunityIcons,
  Octicons,
} from "@expo/vector-icons";
import { SimpleLineIcons } from "@expo/vector-icons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useLogoutMutation } from "../../redux/api/api-slice";
import { unsetUser } from "../../redux/features/user-slice";
import { removeTokens } from "../../redux/features/auth-slice";
import { isErrorResponse } from "../../utility/generalFunctions";
import { FlatList } from "react-native";
import { useState } from "react";
import { format, parseISO } from "date-fns";

export default function ProfileScreen() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.data);
  const [logout] = useLogoutMutation();

  const onLogout = async () => {
    try {
      const response = await logout();
      dispatch(removeTokens());
      dispatch(unsetUser());
      if (isErrorResponse(response)) {
        throw response.error;
      }
    } catch (error) {
      // TODO: show message in Toast
      console.error(error);
    }
  };

  return (
    <FullScreenLayout justifyContent="center">
      <YStack width="100%" gap={18} px={16} backgroundColor="white">
        <XStack gap={8}>
          <Ionicons
            name="person"
            size={20}
            color="#fff"
            style={{
              backgroundColor: "#C8C8C8",
              borderRadius: "30px",
              padding: "10px",
            }}
          />
          <YStack>
            <UIText fontSize={21} fontWeight={"600"}>
              {user?.username}
            </UIText>
            <UIText fontSize={12} fontWeight={"400"} color={"#7e7e7e"}>
              Joined: {user?.created_at ? format(parseISO(user.created_at), 'yyyy/MM/dd') : '-'}
            </UIText>
          </YStack>
        </XStack>

        <XStack width="100%" justifyContent="space-around" paddingTop={10}>
          <YStack
            alignItems="center"
            pressStyle={{ opacity: 0.5 }}
            onPress={() => console.log("TODO: Mark 'made'")}
          >
            <UIText>00</UIText>
            <UIText>Following</UIText>
          </YStack>
          <YStack alignItems="center" pressStyle={{ opacity: 0.5 }}>
            <UIText>00</UIText>
            <UIText>Followers</UIText>
          </YStack>
          <YStack alignItems="center" pressStyle={{ opacity: 0.5 }}>
            <UIText>00</UIText>
            <UIText>Shared</UIText>
          </YStack>
        </XStack>
        <Separator borderColor="#D9D9D9" />

        <div>
          <UIText fontSize={17} fontWeight={"600"}>
            Dietary requirements
          </UIText>
          <XStack gap={4} marginTop={6} flexWrap="wrap" width="100%">
            {(user?.dietary_requirements ?? []).map((item) => {
              return (
                <View
                  key={item.id}
                  borderWidth={1}
                  paddingVertical={4}
                  paddingHorizontal={19}
                  borderColor={"#303030"}
                  borderRadius={4}
                  width="30%"
                >
                  <UIText
                    flex={1}
                    color={"#303030"}
                    fontSize={14}
                    fontWeight={"400"}
                  >
                    {item.requirement}
                  </UIText>
                </View>
              );
            })}
          </XStack>
        </div>
        <Separator borderColor="#D9D9D9" />
        <div>
          <UIText fontSize={17} fontWeight={"600"}>
            Cooking Experience
          </UIText>
          <XStack alignItems="center" gap={8} paddingTop={8}>
            <MaterialCommunityIcons
              name="signal-cellular-2"
              size={22}
              color="black"
            />
            <UIText flex={1} fontSize={16} fontWeight={"400"}>
              {user?.cooking_experience ?? '-'}
            </UIText>
          </XStack>
        </div>

        <Separator borderColor="#D9D9D9" />
        <div>
          <UIText fontSize={17} fontWeight={"600"}>
            Serving Size
          </UIText>
          <XStack alignItems="center" gap={8} paddingTop={8}>
            <Feather name="users" size={22} color="black" />
            <UIText flex={1} fontSize={16} fontWeight={"400"}>
              {user?.serving_size ?? '-'}
            </UIText>
          </XStack>
        </div>
        <Separator borderColor="#D9D9D9" />
        <YStack flexGrow={1}>
          <XStack
            py={10}
            px={8}
            borderWidth={1}
            borderRadius={4}
            right
            gap={8}
            pressStyle={{ opacity: 0.5 }}
            // TODO: get ingredients from my shelf
            onPress={() => console.log("TODO...")}
          >
            <SimpleLineIcons name="support" size={24} color="black" />
            <UIText px={16} flex={1} fontSize={17} fontWeight={400}>
              Support
            </UIText>
            <Octicons
              name="chevron-right"
              size={20}
              color="black"
              style={{ paddingRight: 14 }}
            />
          </XStack>
        </YStack>
        <YStack flexGrow={1}>
          <XStack
            py={10}
            px={8}
            borderWidth={1}
            borderRadius={4}
            gap={8}
            pressStyle={{ opacity: 0.5 }}
            // TODO: get ingredients from my shelf
            onPress={onLogout}
          >
            <Octicons
              name="sign-out"
              style={{ paddingLeft: 8 }}
              flex={1}
              size={20}
              gap={18}
              color="black"
            />

            <UIText px={16} flex={1} fontSize={17} fontWeight={400}>
              Sign Out
            </UIText>
            <Octicons
              name="chevron-right"
              size={20}
              color="black"
              style={{ paddingRight: 14 }}
            />
          </XStack>
        </YStack>
      </YStack>
    </FullScreenLayout>
  );
}
