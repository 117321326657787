import { Button, Label, Spinner, Text, XStack, YStack } from "tamagui";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { useState } from "react";
import {
  debounce,
  isErrorResponse,
  timeout,
} from "../../utility/generalFunctions";
import Ionicons from "@expo/vector-icons/Ionicons";
import { z } from "zod";
import FormInput from "../../components/hook-form/FormInput";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSignUpMutation } from "../../redux/api/api-slice";
import { useAppDispatch } from "../../redux/hooks";
import { setUser } from "../../redux/features/user-slice";
import { setTokens } from "../../redux/features/auth-slice";
import Toast from "react-native-toast-message";

const formSchema = z.object({
  username: z
    .string()
    .min(3, "Username should be at least 3 characters.")
    .max(15, "Username should not exceed 15 characters.")
    .refine((value) => /^[a-zA-Z][a-zA-Z0-9_-]*$/.test(value), {
      message:
        "Invalid username. It should start with an alphabet, and can contain only alphanumeric characters, underscores, and hyphens.",
      path: [], // the path of this error
    }),
  password: z
    .string()
    .min(8, "Password should be at least 8 characters long.")
    .refine((value) => /[A-Z]/.test(value), {
      message: "Password must contain at least one uppercase letter.",
      path: [],
    })
    .refine((value) => /[a-z]/.test(value), {
      message: "Password must contain at least one lowercase letter.",
      path: [],
    })
    .refine((value) => /\d/.test(value), {
      message: "Password must contain at least one digit.",
      path: [],
    })
    .refine((value) => /[!@#$%^&*]/.test(value), {
      message:
        "Password must contain at least one special character (!@#$%^&*).",
      path: [],
    })
    .refine((value) => !/\s/.test(value), {
      message: "Password should not contain spaces.",
      path: [],
    }),
});

export default function SignUpCreateCredentialsScreen({ route }) {
  const dispatch = useAppDispatch();
  const { email } = route.params;

  const [signUp, { isLoading, isError, isSuccess, data, error }] =
    useSignUpMutation();

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(formSchema),
    reValidateMode: "onBlur",
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const [usernameValid, setUsernameValid] = useState<boolean | null>(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isValidatingUsername, setIsValidatingUsername] = useState<
    boolean | null
  >(null);

  const onUserNameChanged = async (username: string) => {
    // TODO: validate username using endpoint, and not whatever is happening here (try avoid React hooks and leverage RTK)...
    setIsValidatingUsername(true);
    await timeout(800);
    setIsValidatingUsername(false);

    if (!username) {
      setUsernameValid(null);
    } else {
      setUsernameValid(!usernameValid);
    }
  };

  const onCompleteSignUp = async ({
    username,
    password,
  }: z.infer<typeof formSchema>) => {
    try {
      const response = await signUp({ username, password, email });
      if (isErrorResponse(response)) {
        throw response.error;
      }
      const { token, token_type, user } = response.data;
      dispatch(setTokens({ token, token_type }));
      dispatch(setUser({ ...user, mustDoOnboarding: true }));
    } catch (error) {
      // TODO: show message in Toast
      console.error(error);
      Toast.show({
        type: "error",
        text1: "Could not sign up",
        text2: "Something went wrong",
      });
    }
  };

  const onTogglePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <FullScreenLayout
      alignItems="flex-start"
      gap={24}
      px={16}
      py={32}
      width="100%"
    >
      <Text
        fontFamily="Inter, Helvetica, Arial, sans-serif"
        fontSize={26}
        fontWeight="600"
      >
        Create your account
      </Text>
      <YStack gap={8} width="100%">
        <Label htmlFor="username" fontSize={13} fontWeight="600" width="100%">
          Username
        </Label>
        <XStack
          alignItems="center"
          backgroundColor="#F5F5F5"
          borderRadius={4}
          borderWidth={0}
          px={12}
        >
          <FormInput
            controllerProps={{
              control,
              name: "username",
            }}
            inputProps={{
              borderWidth: 0,
              focusStyle: { outlineStyle: "none" },
              flex: 1,
              minHeight: 44,
              placeholder: "example_101",
              placeholderTextColor: "#B0B0B0",
              py: 13,
              size: "$4",
              width: "100%",
              onChangeText: debounce(onUserNameChanged, 1000),
            }}
          />
          {/* {isValidatingUsername ? (
            <Spinner size={16} />
          ) : (
            <Ionicons
              color={
                usernameValid === true
                  ? "green"
                  : usernameValid === false
                  ? "red"
                  : "#707070"
              }
              name={
                usernameValid === true
                  ? "checkmark-circle"
                  : usernameValid === false
                  ? "close-circle"
                  : "checkmark-circle-outline"
              }
              size={16}
            />
          )} */}
        </XStack>
        <Text
          fontFamily="Inter, Helvetica, Arial, sans-serif"
          color="#7E7E7E"
          fontSize={12}
        >
          Select a unique username or use our suggestion. You can change this
          later in your profile.
        </Text>
      </YStack>

      <YStack gap={8} width="100%">
        <Label htmlFor="password" fontSize={13} fontWeight="600" width="100%">
          Password
        </Label>
        <XStack
          alignItems="center"
          backgroundColor="#F5F5F5"
          borderRadius={4}
          borderWidth={0}
          px={12}
        >
          <FormInput
            controllerProps={{
              control,
              name: "password",
            }}
            inputProps={{
              borderWidth: 0,
              focusStyle: { outlineStyle: "none" },
              flex: 1,
              minHeight: 44,
              placeholder: "•••••",
              placeholderTextColor: "#B0B0B0",
              py: 13,
              secureTextEntry: !passwordVisible,
              size: "$4",
              width: "100%",
            }}
          />
          <Button
            pressStyle={{
              opacity: 0.5,
              outlineWidth: 0,
            }}
            backgroundColor="#F5F5F5"
            focusStyle={{ borderWidth: 0, outlineWidth: 0 }}
            onPress={onTogglePasswordVisible}
          >
            <Ionicons
              color="#707070"
              name={passwordVisible ? "eye" : "eye-off"}
              size={16}
            />
          </Button>
        </XStack>
      </YStack>

      <Button
        alignItems="center"
        backgroundColor="#000"
        borderRadius={4}
        height={44}
        justifyContent="center"
        width="100%"
        pressStyle={{ opacity: 0.5 }}
        focusStyle={{ borderWidth: 0, outlineWidth: 1 }}
        onPress={handleSubmit(onCompleteSignUp)}
      >
        {isLoading ? (
          <Spinner color="white" />
        ) : (
          <Text
            fontFamily="Inter, Helvetica, Arial, sans-serif"
            color="#FFF"
            fontWeight="600"
          >
            Complete Sign Up
          </Text>
        )}
      </Button>
    </FullScreenLayout>
  );
}
