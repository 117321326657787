import enUS from "date-fns/locale/en-US";

export function getCurrentTimeInSeconds() {
  return Math.round(Date.now() / 1000);
}

// Custom locale to set time measure texts
export const customLocale = {
  ...enUS,
  formatDistance: (token, count, options) => {
    const replacements = {
      second: "sec",
      minute: "min",
      // hour: 'hr',
      // day: 'day',
      month: "mnth",
      // year: 'yr'
    };

    let result = enUS.formatDistance(token, count, options);

    // Replace each time word based on our replacements map
    for (const [key, value] of Object.entries(replacements)) {
      const regex = new RegExp(key + (count === 1 ? "" : "s"), "g");
      result = result.replace(regex, value + (count === 1 ? "" : "s")); // This is the corrected line
    }

    return result;
  },
};
