import { Popover, Square, XStack, YStack } from "tamagui";
import { UIText } from "../UIText";
import Ionicons from "@expo/vector-icons/Ionicons";

export default function IngredientCard({
  name,
  title,
  recipes = null,
  onCardPress,
  onRemove,
}) {
  let pressedInside = false;

  const onOptionsPress = (e) => {
    // TODO...
    console.log("Option press");
  };

  return (
    <XStack
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      paddingBottom={16}
      borderColor="#D9D9D9"
      borderBottomWidth={1}
      pressStyle={{ opacity: 0.5 }}
      onPress={() => {
        if (!pressedInside) {
          onCardPress({ name, title, recipes }); // TODO: pass relevant data (maybe ID?) to onCardPress
        }
        pressedInside = false;
      }}
    >
      <YStack flex={1} gap={4}>
        <UIText fontSize={17}>{title}</UIText>
        {!!recipes && (
          <UIText fontSize={12} color="#636363">
            In {recipes} recipes
          </UIText>
        )}
      </YStack>
      <Popover>
        <Popover.Trigger>
          <Square
            borderRadius={6}
            padding={6}
            pressStyle={{ opacity: 0.5 }}
            onPressIn={() => {
              pressedInside = true;
            }}
            onPress={onOptionsPress}
          >
            <Ionicons name="ellipsis-horizontal" size={20} />
          </Square>
        </Popover.Trigger>
        {!!onRemove && (
          <Popover.Content
            borderColor="#B0B0B0"
            borderRadius={8}
            borderWidth={1}
            px={16}
            py={16}
            elevate
            backgroundColor="white"
          >
            <Popover.Arrow borderWidth={1} borderColor="#B0B0B0" />
            <YStack justifyContent="center">
              <XStack
                alignItems="center"
                justifyContent="center"
                gap={4}
                pressStyle={{ opacity: 0.5 }}
                onPress={(e) => {
                  e.stopPropagation();
                  onRemove(e, name);
                  // handle delete action
                }}
              >
                <UIText color="red" fontWeight={600} alignItems="center">
                  Remove
                </UIText>
                <Ionicons name="trash" color="red" size={16} />
              </XStack>
            </YStack>
          </Popover.Content>
        )}
      </Popover>
    </XStack>
  );
}
