import { UIText } from "../../components";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { ScrollView, Separator, XStack, YStack } from "tamagui";
import { formatDistanceToNow } from "date-fns";
import { Image } from "expo-image";
import { FlatList } from "react-native";
import { customLocale } from "../../utility/dateFunctions";
import { useNavigation } from "@react-navigation/native";
import dummyRecipes from "../../dummyData/recipes";

function IngredientRecipeCard({ recipe }) {
  const { numberOfIngredients, title, image, dateAdded } = recipe;
  const { navigate } = useNavigation();
  const onCardPress = () => {
    navigate("Recipe", { recipe });
  };

  return (
    <XStack
      minHeight={102}
      py={16}
      pressStyle={{ opacity: 0.5 }}
      onPress={onCardPress}
    >
      <YStack flex={1} gap={8}>
        <UIText fontSize={17} fontWeight={600}>
          {title}
        </UIText>
        <UIText>{numberOfIngredients} Ingredients</UIText>
        <UIText color="#4D4C4C" fontSize={11}>
          Added{" "}
          {formatDistanceToNow(dateAdded, {
            locale: customLocale,
            addSuffix: true,
          })}
        </UIText>
      </YStack>
      <Image
        style={{
          height: "100%",
          minHeight: 102,
          minWidth: 124,
          backgroundColor: "#EFEFEF",
          borderRadius: 10,
        }}
        source={{ uri: image }}
        // placeholder={blurhash}
        contentFit="cover"
        transition={1000}
      />
    </XStack>
  );
}

export default function IngredientRecipesScreen({ route }) {
  const { title: ingredientTitle = "TODO: ingredient not found" } =
    route.params;

  const dummyIngredientRecipes = dummyRecipes.filter((recipe) =>
    recipe.ingredients.find((ingredient) =>
      ingredient
        .toLowerCase()
        .includes(ingredientTitle.substring(0, 5).trim().toLowerCase())
    )
  );

  return (
    <ScrollView>
      <FullScreenLayout px={16} py={16} width="100%">
        <YStack width="100%" gap={24}>
          <YStack width="100%" gap={4}>
            <UIText fontSize={21}>{ingredientTitle}</UIText>
            <UIText color="#303030" fontSize={12} fontWeight={300}>
              Added 30 days ago
            </UIText>
          </YStack>
          <UIText color="#4D4C4C">
            All the recipes associated to this ingredient are listed below
          </UIText>
          <FlatList
            data={dummyIngredientRecipes}
            renderItem={({ item }) => <IngredientRecipeCard recipe={item} />}
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={
              <Separator borderBottomWidth={1} borderColor="#EBEAEA" />
            }
          />
        </YStack>
      </FullScreenLayout>
    </ScrollView>
  );
}
