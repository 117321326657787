import FullScreenLayout from "../../layouts/FullScreenLayout";
import { Text, View, XStack, YStack } from "tamagui";
import * as Progress from "react-native-progress";
import { Dimensions } from "react-native";
import { useState } from "react";
import { AppUser, IOnboardingData } from "../../types";
import DietaryRequirements from "../../sections/onboarding/DietaryRequirements";
import { UIText } from "../../components";
import CookingExperienceLevel from "../../sections/onboarding/CookingExperienceLevel";
import ServingSize from "../../sections/onboarding/ServingSize";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useOnboardMutation } from "../../redux/api/api-slice";
import {
  isErrorResponse,
  objectsAreEqual,
} from "../../utility/generalFunctions";
import { setUser } from "../../redux/features/user-slice";

interface IOnboardingStep {
  name: keyof IOnboardingData;
  Component: React.ElementType;
  validate(onboardingData: unknown): boolean;
}

const onboardingSteps: IOnboardingStep[] = [
  {
    name: "dietaryRequirements",
    Component: DietaryRequirements,
    validate: function (onboardingData) {
      if (!onboardingData[this.name]?.length) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
  {
    name: "experience",
    Component: CookingExperienceLevel,
    validate: function (onboardingData) {
      if (!onboardingData[this.name]?.length) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
  {
    name: "servingSize",
    Component: ServingSize,
    validate: function (onboardingData) {
      if (!onboardingData[this.name]?.length) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
];

const defaultValues: IOnboardingData = {
  dietaryRequirements: [],
  experience: "",
  servingSize: null,
};

export default function OnboardingScreen({ navigation }) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [onboardUser, { isLoading, error }] = useOnboardMutation();
  const [stepNumber, setStepNumber] = useState(1);
  const [userOnboardingData, setUserOnboardingData] =
    useState<IOnboardingData>(defaultValues);

  const isBackDisabled = stepNumber <= 1;
  const isLastStep = stepNumber >= onboardingSteps.length;

  const onOnboardUser = async () => {
    if (objectsAreEqual(defaultValues, userOnboardingData)) {
      console.warn("No preferences set during onboarding."); // TODO: use toast
    } else {
      const response = await onboardUser(userOnboardingData);
      if (isErrorResponse(response)) {
        console.error(response.error); // TODO: use toast
      } else {
        console.log("User updated!", response);
        // TODO: use toast
      }
    }

    // TODO: update user state if network request happened successfully
    dispatch(setUser({ ...(user.data as AppUser), mustDoOnboarding: false }));
    navigation.reset({
      index: 0,
      routes: [{ name: "Home" }],
    });
  };

  const onNext = () => {
    onboardingSteps[stepNumber - 1].validate(userOnboardingData);
    if (isLastStep) {
      onOnboardUser();
      return;
    }
    setStepNumber(stepNumber + 1);
  };

  const onBack = () => {
    if (isBackDisabled) {
      return;
    }
    setStepNumber(stepNumber - 1);
  };

  const CurrentStep: React.ElementType =
    onboardingSteps[stepNumber - 1].Component;

  return (
    <FullScreenLayout
      alignItems="flex-start"
      gap={24}
      px={16}
      paddingTop={66}
      paddingBottom={36}
      width="100%"
    >
      <YStack width="100%" height="100%" gap={24}>
        <Progress.Bar
          animated
          borderWidth={1}
          borderColor="#B0B0B0"
          borderRadius={0}
          height={8}
          width={Dimensions.get("window").width - 32}
          unfilledColor="transparent"
          color="#303030"
          progress={stepNumber / onboardingSteps.length}
          fill="transparent"
        />
        {
          <CurrentStep
            userOnboardingData={userOnboardingData}
            setUserOnboardingData={setUserOnboardingData}
          />
        }
        <YStack width="100%" justifyContent="flex-end" flexGrow={1}>
          <XStack gap={16}>
            <View
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
              borderWidth={1}
              borderRadius={4}
              borderColor={isBackDisabled ? "grey" : "black"}
              py={13}
              disabled={isBackDisabled}
              onPress={onBack}
            >
              <UIText
                pressStyle={isBackDisabled ? {} : { opacity: 0.5 }}
                color={isBackDisabled ? "grey" : "black"}
              >
                Back
              </UIText>
            </View>
            <View
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
              borderRadius={4}
              backgroundColor="black"
              py={13}
              onPress={onNext}
            >
              <UIText pressStyle={{ opacity: 0.5 }} color="white">
                {isLastStep ? "Complete" : "Next"}
              </UIText>
            </View>
          </XStack>
        </YStack>
        <XStack justifyContent="center">
          <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="#7E7E7E" fontSize={12} textAlign="center">
            You can change these options later in your Profile
          </Text>
        </XStack>
      </YStack>
    </FullScreenLayout>
  );
}
