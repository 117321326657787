import { useEffect, useState } from "react";
import { Button, Spinner, XStack, YStack } from "tamagui";
import { UIText } from "../../components/UIText";
import { FlatList, Platform, ScrollView } from "react-native";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { useGetRecipesSharedWithMeQuery } from "../../redux/api/api-slice";
import SharedMealsCard from "../../sections/sharedMeals/SharedMealsCard";
import Ionicons from "@expo/vector-icons/Ionicons";

export default function SharedMeals({ navigation }) {
  const [viewMode, setViewMode] = useState<"grid" | "list">("list");
  const [recipes, setRecipes] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const { data, isFetching, refetch } = useGetRecipesSharedWithMeQuery({
    page,
  });

  useEffect(() => {
    if (data?.data) {
      setRecipes((prevRecipes) => [...prevRecipes, ...data.data]);
    }
  }, [data]);

  const handleLoadMore = async () => {
    if (Platform.OS === "web") {
      return;
    }
    if (!isFetching && data.next_page_url) {
      setPage((currentPage) => currentPage + 1);
      await refetch();
    }
  };

  const handleScroll = async ({ nativeEvent }) => {
    const isCloseToBottom =
      nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y >=
      nativeEvent.contentSize.height - 20;
    if (isCloseToBottom && !isFetching) {
      if (!isFetching && data.next_page_url) {
        setPage((currentPage) => currentPage + 1);
        await refetch();
      }
    }
  };

  const renderFooter = () => {
    if (!isFetching) return null;

    return <Spinner size={16} />;
  };

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <XStack alignItems="center" gap={6} height="100%" px={8} py={4}>
          <Button
            backgroundColor={viewMode === "grid" ? "#CCC" : "white"}
            borderRadius={6}
            borderWidth={0}
            outlineWidth={0}
            height={32}
            onPress={() => setViewMode("grid")}
            pressStyle={{
              opacity: 0.5,
              outlineWidth: 0,
              backgroundColor: "transparent",
            }}
            focusStyle={{ borderWidth: 0, outlineWidth: 0 }}
          >
            <Ionicons name="grid-outline" size={24} />
          </Button>
          <Button
            backgroundColor={viewMode === "list" ? "#CCC" : "white"}
            borderRadius={6}
            borderWidth={0}
            outlineWidth={0}
            height={32}
            onPress={() => setViewMode("list")}
            pressStyle={{
              opacity: 0.5,
              outlineWidth: 0,
              backgroundColor: "transparent",
            }}
            focusStyle={{ borderWidth: 0, outlineWidth: 0 }}
          >
            <Ionicons name="list-outline" size={24} />
          </Button>
          {/* <Button
            backgroundColor="white"
            height={32}
            pressStyle={{
              opacity: 0.5,
              outlineWidth: 0,
              backgroundColor: "transparent",
            }}
            focusStyle={{ borderWidth: 0, outlineWidth: 0 }}
          >
            <Ionicons name="filter-outline" size={24} />
          </Button> */}
        </XStack>
      ),
    });
  }, [navigation, viewMode]);

  return (
    <ScrollView onScroll={handleScroll} scrollEventThrottle={400}>
      <FullScreenLayout paddingTop={16}>
        <YStack width="100%" gap={18} px={16} backgroundColor="white">
          <XStack
            width="100%"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <UIText fontSize={20} fontWeight={600}>
              Meals Shared w/ me
            </UIText>
          </XStack>
          <FlatList
            key={viewMode}
            data={recipes}
            renderItem={({ item }) => (
              <SharedMealsCard
                recipe={{ ...item, image: item.images?.split(",")[0] }}
                cardStyle={{
                  width: viewMode === "list" ? "100%" : "50%",
                }}
              />
            )}
            keyExtractor={(item) => item.id}
            ListFooterComponent={renderFooter}
            numColumns={viewMode === "list" ? 1 : 2}
            onEndReached={handleLoadMore}
            onEndReachedThreshold={0.5}
          />
        </YStack>
      </FullScreenLayout>
    </ScrollView>
  );
}
