import { Text } from "tamagui";

/**
 * For some reason expo-font does not have any way of mapping custom fonts to weight,
 * so this object does that for the Lato font.
 */

const weightNameMap = {
  100: "Thin",
  200: "Thin",
  300: "Light",
  400: "Regular",
  500: "Regular",
  600: "Regular",
  700: "Bold",
  800: "Bold",
  900: "Black",
};

function getGoogleFontName(font = "Lato", weight = 400, fontStyle) {
  let fontName = `${font}_${weight}${weightNameMap[weight]}`;
  if (fontStyle === "italic") {
    fontName += "_Italic";
  }
  return fontName;
}

/**
 * Normally we would use Tamagui and setup default values in ./tamagui.config.js,
 * however on web the fonts don't pull through.
 * This component is used to setup default texts across the app.
 */
export function UIText(props) {
  return (
    <Text
      fontFamily={getGoogleFontName("Lato", props.fontWeight, props.fontStyle)}
      fontSize={props.isHeading ? 20 : 14}
      fontWeight={props.isHeading ? 600 : 400}
      {...props}
    />
  );
}
