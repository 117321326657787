import { XStack, YStack } from "tamagui";
import { UIText } from "../../components";
import { TouchableOpacity } from "react-native";
import { IOnboardingData } from "../../types";

interface IProps {
  userOnboardingData: IOnboardingData;
  setUserOnboardingData: React.Dispatch<React.SetStateAction<IOnboardingData>>;
}

interface IOptionProps {
  servingSize: string;
  isSelected?: boolean;
}

const servingSizes = [
  ...Array.from({ length: 7 }, (_, i) => (i + 1).toString()),
  "+8",
];

function ServingSizeOption({ servingSize, isSelected }: IOptionProps) {
  return (
    <YStack
      alignItems="center"
      gap={3}
      width="100%"
      borderWidth={1}
      borderRadius={4}
      px={16}
      py={12}
      backgroundColor={isSelected ? "#EFEFEF" : undefined} // TODO: what color should selection be?
    >
      <UIText fontSize={26} color="#636363">
        {servingSize}
      </UIText>
      <UIText>Serving{Number(servingSize) > 1 && "s"}</UIText>
    </YStack>
  );
}

export default function ServingSize({
  userOnboardingData,
  setUserOnboardingData,
}: IProps) {
  const selectedServingSize = userOnboardingData.servingSize;

  const onSelect = (newSelectedServingSize: string) => {
    // should we check if it can be converted to a number?
    /**
     * TODO: considering this will ultimately become a prompt, converting "+8" into the number 8 is not helpful,
     * maybe servingSize should be a string, and the option object should include a display text (e.g. "+8")
     * as well as a prompt string (e.g. "8 or more"), where the prompt string becomes servingSize
     */
    setUserOnboardingData((prev) => ({
      ...prev,
      servingSize: Number(newSelectedServingSize),
    }));
  };

  return (
    <YStack alignItems="center" gap={36}>
      <UIText fontSize={21}>
        How much time do you have to prepare this meal?
      </UIText>
      <XStack gap={12} width="100%" flexWrap="wrap">
        {servingSizes.map((servingSize) => {
          const isSelected = Number(servingSize) === selectedServingSize;
          return (
            <TouchableOpacity
              key={servingSize}
              onPress={() => onSelect(servingSize)}
              style={{ width: "30%" }}
            >
              <ServingSizeOption
                servingSize={servingSize}
                isSelected={isSelected}
              />
            </TouchableOpacity>
          );
        })}
      </XStack>
    </YStack>
  );
}
