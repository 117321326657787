import { XStack, YStack } from "tamagui";
import { UIText } from "../../components/UIText";
import { FlatList, TouchableOpacity } from "react-native";
import SharedMealsCard from "./SharedMealsCard";
import { useNavigation } from "@react-navigation/native";
import { useGetRecipesSharedWithMeQuery } from "../../redux/api/api-slice";

export default function SharedMeals() {
  const { navigate } = useNavigation();
  const { data } = useGetRecipesSharedWithMeQuery({ page: 1 });
  const recipes = data?.data ?? [];

  return (
    <YStack width="100%" gap={18}>
      <XStack width="100%" justifyContent="space-between" alignItems="end">
        <UIText fontSize={20}>Meals Shared w/ me</UIText>
        {!!recipes?.length && (
          <TouchableOpacity onPress={() => navigate("SharedMeals")}>
            <UIText textDecorationLine="underline" color="#4D4C4C">
              See All
            </UIText>
          </TouchableOpacity>
        )}
      </XStack>
      {recipes?.length ? (
        <FlatList
          data={recipes.slice(0, 3)}
          renderItem={({ item }) => (
            <SharedMealsCard
              recipe={{ ...item, image: item.images?.split(",")[0] }}
            />
          )}
          keyExtractor={(item) => item.id}
        />
      ) : (
        <YStack
          width="100%"
          justifyContent="center"
          alignItems="center"
          py={16}
        >
          <UIText color="#4D4C4C" textAlign="center">
            No shared meals yet.
          </UIText>
        </YStack>
      )}
    </YStack>
  );
}
