import { YStack } from "tamagui";
import { UIText } from "../../components";
import { TouchableOpacity } from "react-native";

const opennessToAdditionalIngredientsOptions = [
  {
    heading: "Keep it light",
    description: "Only a few ingredients will be added",
    promptValue: "adding only a few more ingredients (less than 5)",
  },
  {
    heading: "A handful is fine",
    description: "A bit more than 5 ingredients",
    promptValue: "adding a bit more than 5 ingredients",
  },
  {
    heading: "Open to a basket of goods",
    description: "You’ll probably need one for some recipes",
    promptValue: "adding a basket full of additional ingredients",
  },
  {
    heading: "Trolley ready!",
    description: "Get ready to browse those aisles for these items",
    promptValue: "adding as many more ingredients as I'll need",
  },
];

function OpennessToMoreIngredientsOption({ opennessOption, isSelected }) {
  return (
    <YStack
      gap={2}
      width="100%"
      borderWidth={1}
      borderRadius={4}
      px={16}
      py={12}
      backgroundColor={isSelected ? "#EFEFEF" : undefined} // TODO: what color should selection be?
    >
      <UIText>{opennessOption.heading}</UIText>
      <UIText fontSize={12} color="#646464">
        {opennessOption.description}
      </UIText>
    </YStack>
  );
}

export default function OpennessToMoreIngredients({
  promptData,
  setGeneratePrompt,
}) {
  const selectedOpennessToMoreIngredients =
    promptData.opennessToAdditionalIngredients;

  const onSelect = (newSelectedOpennessToMoreIngredients) => {
    setGeneratePrompt((prev) => ({
      ...prev,
      opennessToAdditionalIngredients: newSelectedOpennessToMoreIngredients,
    }));
  };

  return (
    <YStack alignItems="center" gap={36}>
      <UIText fontSize={21}>How open are you to additional ingredients?</UIText>
      <YStack gap={12} width="100%">
        {opennessToAdditionalIngredientsOptions.map(
          (opennessToAdditionalIngredientsOption) => {
            const isSelected =
              opennessToAdditionalIngredientsOption.promptValue ===
              selectedOpennessToMoreIngredients;
            return (
              <TouchableOpacity
                key={opennessToAdditionalIngredientsOption.heading}
                onPress={() =>
                  onSelect(opennessToAdditionalIngredientsOption.promptValue)
                }
              >
                <OpennessToMoreIngredientsOption
                  opennessOption={opennessToAdditionalIngredientsOption}
                  isSelected={isSelected}
                />
              </TouchableOpacity>
            );
          }
        )}
      </YStack>
    </YStack>
  );
}
