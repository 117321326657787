import { TouchableOpacity } from "react-native";
import { UIText } from "../../components";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { YStack, XStack, View } from "tamagui";
import { useEffect, useState } from "react";
import MealType from "../../sections/generate/MealType";
import AvailableTime from "../../sections/generate/AvailableTime";
import AvailableIngredient from "../../sections/generate/AvailableIngredients";
import PreferredCookingMethod from "../../sections/generate/PreferredCookingMethod";
import OpennessToMoreIngredients from "../../sections/generate/OpennessToMoreIngredients";
import AdditonalComments from "../../sections/generate/AdditionalComments";
import TaskProgressScreen from "./TaskProgress";
import useDummyProgress from "../../hooks/useDummyProgress";
import * as Progress from "react-native-progress";
import { Dimensions } from "react-native";
import { useSubmitPromptMutation } from "../../redux/api/api-slice";

const generateSteps = [
  {
    name: "mealType",
    Component: MealType,
    validate: function (promptData) {
      if (!promptData[this.name]) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
  {
    name: "availableTime",
    Component: AvailableTime,
    validate: function (promptData) {
      if (!promptData[this.name]) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
  {
    name: "availableIngredients",
    Component: AvailableIngredient,
    validate: function (promptData) {
      if (!promptData[this.name]) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
  {
    name: "preferredCookingMethod",
    Component: PreferredCookingMethod,
    validate: function (promptData) {
      if (!promptData[this.name]) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
  {
    name: "opennessToAdditionalIngredients",
    Component: OpennessToMoreIngredients,
    validate: function (promptData) {
      if (!promptData[this.name]) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
  {
    name: "additionalComments",
    Component: AdditonalComments,
    validate: function (promptData) {
      if (!promptData[this.name]) {
        // TODO...
        console.warn(`You have not selected a ${this.name}`);
        return false;
      }
      return true;
    },
  },
];

export default function GenerateScreen({ navigation }) {
  const [stepNumber, setStepNumber] = useState(1);
  const [generatePrompt, setGeneratePrompt] = useState({
    mealType: null,
    availableTime: null,
    availableIngredients: [],
    preferredCookingMethod: null,
    opennessToAdditionalIngredients: null,
    additionalComments: null,
  });
  const [isProcessingPrompt, setIsProcessingPrompt] = useState(false);

  const isBackDisabled = stepNumber <= 1;
  const isLastStep = stepNumber >= generateSteps.length;

  const onNext = () => {
    generateSteps[stepNumber - 1].validate(generatePrompt);
    if (isLastStep) {
      // TODO: submit
      console.log("Submit", { generatePrompt });
      setIsProcessingPrompt(true);
      return;
    }
    setStepNumber(stepNumber + 1);
  };

  const onBack = () => {
    if (isBackDisabled) {
      return;
    }
    setStepNumber(stepNumber - 1);
  };

  const CurrentStep = generateSteps[stepNumber - 1].Component;

  const [submitPrompt, { isLoading, isError, isSuccess, data, error }] =
    useSubmitPromptMutation();

  const onSubmitPrompt = async () => {
    try {
      const response = await submitPrompt({ generatePrompt });

      console.log("RESP", response.data.data)
      navigation.navigate("Home", {
        screen: "GenerateTab",
        params: { recipes: response.data.data,
        prompt:generatePrompt },
      });
    } catch (error) {
      // TODO: show message in Toast
      console.error(error);
    }
  };

  useEffect(() => {
    if (isProcessingPrompt) {
      onSubmitPrompt();
    }
  }, [isProcessingPrompt]);

  const onGenerateCompletion = async () => {};

  const { currentProgress, currentSubtasks, errorMessage, taskTitle } =
    useDummyProgress({
      taskTitle: "Mixing it up! Preparing your meal recommendations...",
      completedSubtasks: [
        "Applying your dietary requirements and experience",
        `Your ${
          generatePrompt.availableIngredients.length || 0
        } available ingredients have been splashed in`,
        `Some meal ideas are being cooked up`,
        "Finalizing custom meal suggestions!",
      ],
      // onCompletion: onGenerateCompletion,
      errorMessage: null,
      startProgress: isProcessingPrompt,
      isPromptLoading: isLoading,
    });
  if (isProcessingPrompt) {
    return (
      <TaskProgressScreen
        taskTitle={taskTitle}
        progress={currentProgress}
        completedSubtasks={currentSubtasks}
        // onCompletion={onGenerateCompletion}
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <FullScreenLayout justifyContent="center" px={16} py={16}>
      <YStack width="100%" height="100%" gap={24}>
        <XStack width="100%" justifyContent="space-between" alignItems="center">
          <TouchableOpacity onPress={navigation.goBack}>
            <UIText color="#414141">Cancel</UIText>
          </TouchableOpacity>
          <UIText>
            Step {stepNumber} of {generateSteps.length}
          </UIText>
          <TouchableOpacity onPress={onNext} disabled={isLastStep}>
            <UIText color={isLastStep ? "transparent" : "#414141"}>Skip</UIText>
          </TouchableOpacity>
        </XStack>

        <Progress.Bar
          animated
          borderWidth={1}
          borderColor="#B0B0B0"
          borderRadius={0}
          height={8}
          width={Dimensions.get("window").width - 32}
          unfilledColor="transparent"
          color="#303030"
          progress={stepNumber / generateSteps.length}
          fill="transparent"
        />
        {
          <CurrentStep
            promptData={generatePrompt}
            setGeneratePrompt={setGeneratePrompt}
          />
        }
        <YStack width="100%" justifyContent="flex-end" flexGrow={1}>
          <XStack gap={16}>
            <View
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
              borderWidth={1}
              borderRadius={4}
              borderColor={isBackDisabled ? "grey" : "black"}
              py={13}
              disabled={isBackDisabled}
              onPress={onBack}
            >
              <UIText
                pressStyle={isBackDisabled ? {} : { opacity: 0.5 }}
                color={isBackDisabled ? "grey" : "black"}
              >
                Back
              </UIText>
            </View>
            <View
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
              borderRadius={4}
              backgroundColor="black"
              py={13}
              onPress={onNext}
            >
              <UIText pressStyle={{ opacity: 0.5 }} color="white">
                {isLastStep ? "Submit" : "Next"}
              </UIText>
            </View>
          </XStack>
        </YStack>
      </YStack>
    </FullScreenLayout>
  );
}
