import { ScrollView, Spinner, Stack, Text, XStack, YStack } from "tamagui";
import { FlatList } from "react-native";
import { useGetPromptsQuery } from "../../redux/api/api-slice";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import PreviousPromptsCard from "../../sections/previousPrompts/PreviousPromptsCard";

export default function PreviousPromptsScreen() {
  const {
    isLoading, // TODO: handle
    data: prompts = [],
    error, // TODO: handle
  } = useGetPromptsQuery();

  if (isLoading) {
    return (
      <FullScreenLayout justifyContent="center">
        <Spinner color="black" size="large" />
      </FullScreenLayout>
    );
  }

  return (
    <ScrollView>
      <FullScreenLayout py={16}>
        <YStack width="100%" gap={18} px={16}>
          <XStack
            width="100%"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontSize={21} fontWeight="400" /*<-600*/>
              Previous Prompts
            </Text>
          </XStack>
          <FlatList
            data={prompts}
            renderItem={({ item, index }) => {
              const description = `${item.meal_type}, incl. ${
                item.ingredients.split(",").length
              } ingredients, using ${
                item.cooking_method.split("(")[0]
              }, for 1 serving${
                item.additional_comments ? `, ${item.additional_comments}` : ""
              }`;
              return (
                <PreviousPromptsCard
                  id={item.id}
                  number={index + 1}
                  date={item.created_at}
                  description={description}
                />
              );
            }}
            keyExtractor={(item) => item.id}
            ItemSeparatorComponent={
              <Stack borderBottomWidth={1} borderColor="#EBEAEA" />
            }
          />
        </YStack>
      </FullScreenLayout>
    </ScrollView>
  );
}
