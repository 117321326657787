import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import reduxConstants from "../reduxConstants";
import { apiSlice } from "../api/api-slice";

export const RECIPE_DATA_KEY = "foodx_recipes";

interface IStateProps {
  savedRecipes: (string | number)[];
  recipesGeneratingInstructions: (string | number)[];
}

const initialState: IStateProps = {
  savedRecipes: [],
  recipesGeneratingInstructions: [], // Tracks loading state for each recipe
};

const slice = createSlice({
  name: reduxConstants.RECIPE.NAME,
  initialState,
  reducers: {
    saveRecipe: (
      state,
      { payload: recipe }: PayloadAction<string | number>
    ) => {
      if (state.savedRecipes.includes(recipe)) {
        return state;
      }
      state.savedRecipes.push(recipe);
    },
    unsaveRecipe: (state, { payload: recipe }: PayloadAction<string>) => {
      if (!state.savedRecipes.includes(recipe)) {
        return state;
      }
      const indexToRemove = state.savedRecipes.indexOf(recipe);
      if (indexToRemove < 0) {
        return state;
      }
      state.savedRecipes.splice(indexToRemove, 1);
    },
    isGeneratingInstructions: (
      state,
      action: PayloadAction<string | number>
    ) => {
      state.recipesGeneratingInstructions.push(action.payload);
    },
    isNotGeneratingInstructions: (
      state,
      action: PayloadAction<string | number>
    ) => {
      state.recipesGeneratingInstructions =
        state.recipesGeneratingInstructions.filter(
          (id) => id !== action.payload
        );
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      apiSlice.endpoints.getRecipes.matchFulfilled,
      (state, action) => {
        const savedRecipesFromServer = action.payload;
        if (!Array.isArray(savedRecipesFromServer)) {
          return state;
        }
        state.savedRecipes = savedRecipesFromServer
          .filter(
            (recipe) =>
              typeof recipe.id === "string" || typeof recipe.id === "number"
          )
          .map((recipe) => recipe.id);
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.saveRecipe.matchFulfilled,
      (state, action) => {
        if (
          !action.payload.id ||
          !(typeof action.payload.saved === "boolean")
        ) {
          return state;
        }
        const recipeId = action.payload?.id;
        const isRecipeSaved = action.payload?.saved;
        if (isRecipeSaved && !state.savedRecipes.includes(recipeId)) {
          state.savedRecipes.push(recipeId);
        } else if (!isRecipeSaved && state.savedRecipes.includes(recipeId)) {
          const indexToRemove = state.savedRecipes.indexOf(recipeId);
          if (indexToRemove < 0) {
            return state;
          }
          state.savedRecipes.splice(indexToRemove, 1);
        }
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.generateRecipeInstructions.matchPending,
      (state, action) => {
        state.recipesGeneratingInstructions.push(
          action.meta.arg.originalArgs.id
        );
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.generateRecipeInstructions.matchFulfilled,
      (state, action) => {
        state.recipesGeneratingInstructions =
          state.recipesGeneratingInstructions.filter(
            (id) => id !== action.meta.arg.originalArgs.id
          );
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.generateRecipeInstructions.matchRejected,
      (state, action) => {
        state.recipesGeneratingInstructions =
          state.recipesGeneratingInstructions.filter(
            (id) => id !== action.meta.arg.originalArgs.id
          );
      }
    );
  },
});

export const { saveRecipe, unsaveRecipe } = slice.actions;

export default slice.reducer;
