import { z } from "zod";
import { useState } from "react";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { Button, Label, Spinner, Text, XStack, YStack } from "tamagui";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FormInput from "../../components/hook-form/FormInput";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useLoginMutation } from "../../redux/api/api-slice";
import { isErrorResponse } from "../../utility/generalFunctions";
import { useAppDispatch } from "../../redux/hooks";
import { setTokens } from "../../redux/features/auth-slice";
import { setUser } from "../../redux/features/user-slice";
import Toast from "react-native-toast-message";

const formSchema = z.object({
  email: z.string().email("Please enter a valid email"),
  password: z.string().min(1, "Please enter a password"),
});

export default function LoginScreen() {
  const dispatch = useAppDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [login, { isLoading, error }] = useLoginMutation();

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(formSchema),
    reValidateMode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onTogglePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onLogin = async ({ email, password }: z.infer<typeof formSchema>) => {
    try {
      const response = await login({ email, password });
      if (isErrorResponse(response)) {
        throw response.error;
      }
      const {
        access_token: token,
        token_type = "Bearer",
        user,
      } = response.data.data;

      dispatch(setTokens({ token, token_type }));
      dispatch(setUser(user));
    } catch (error) {
      // TODO: show message in Toast
      console.error(error);
      Toast.show({
        type: "error",
        text1: "Could not log in",
        text2: `Something went wrong: ${error?.messgae}`,
      });
    }
  };

  return (
    <FullScreenLayout
      alignItems="flex-start"
      gap={24}
      px={16}
      py={32}
      width="100%"
    >
      <Text
        fontFamily="Inter, Helvetica, Arial, sans-serif"
        fontSize={26}
        fontWeight="600"
      >
        Login
      </Text>
      <YStack gap={8} width="100%">
        <Label htmlFor="email" fontSize={13} fontWeight="600" width="100%">
          Email
        </Label>
        <XStack
          alignItems="center"
          backgroundColor="#F5F5F5"
          borderRadius={4}
          borderWidth={0}
          px={12}
        >
          <FormInput
            controllerProps={{
              control,
              name: "email",
            }}
            inputProps={{
              borderWidth: 0,
              focusStyle: { outlineStyle: "none" },
              flex: 1,
              minHeight: 44,
              placeholder: "example@mail.com",
              placeholderTextColor: "#B0B0B0",
              py: 13,
              size: "$4",
              width: "100%",
            }}
          />
        </XStack>
      </YStack>

      <YStack gap={8} width="100%">
        <Label htmlFor="password" fontSize={13} fontWeight="600" width="100%">
          Password
        </Label>
        <XStack
          alignItems="center"
          backgroundColor="#F5F5F5"
          borderRadius={4}
          borderWidth={0}
          px={12}
        >
          <FormInput
            controllerProps={{
              control,
              name: "password",
            }}
            inputProps={{
              borderWidth: 0,
              focusStyle: { outlineStyle: "none" },
              flex: 1,
              minHeight: 44,
              placeholder: "•••••",
              placeholderTextColor: "#B0B0B0",
              py: 13,
              secureTextEntry: !passwordVisible,
              size: "$4",
              width: "100%",
            }}
          />
          <Button
            pressStyle={{
              opacity: 0.5,
              outlineWidth: 0,
            }}
            backgroundColor="#F5F5F5"
            focusStyle={{ borderWidth: 0, outlineWidth: 0 }}
            onPress={onTogglePasswordVisible}
          >
            <Ionicons
              color="#707070"
              name={passwordVisible ? "eye" : "eye-off"}
              size={16}
            />
          </Button>
        </XStack>
      </YStack>

      <Button
        alignItems="center"
        backgroundColor="#000"
        borderRadius={4}
        height={44}
        justifyContent="center"
        width="100%"
        pressStyle={{ opacity: 0.5 }}
        focusStyle={{ borderWidth: 0, outlineWidth: 1 }}
        onPress={handleSubmit(onLogin)}
      >
        {isLoading ? (
          <Spinner color="white" />
        ) : (
          <Text
            fontFamily="Inter, Helvetica, Arial, sans-serif"
            color="#FFF"
            fontWeight="600"
          >
            Login
          </Text>
        )}
      </Button>
    </FullScreenLayout>
  );
}
