import { Button, Label, Separator, Text, XStack, YStack } from "tamagui";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import Ionicons from "@expo/vector-icons/Ionicons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import FormInput from "../../components/hook-form/FormInput";

export default function SignUpScreen({ navigation }) {
  const formSchema = z.object({
    email: z.string().email("Please enter a valid email"),
  });

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(formSchema),
    reValidateMode: "onBlur",
    defaultValues: {
      email: "",
    },
  });

  const onContinueWithEmail = ({ email }) => {
    // TODO:
    // - validate email
    // - we should probably let them know if the email is already in use here? or is it a deterrent for nefarious actors if they only find out later on account creation screen 2 submission?
    // - navigate to account creation screen 2
    navigation.navigate("SignUpCreateCredentials", { email });
    // - for 'Continue With [SSO Platform]', we will only provide username on account creation screen 2 assuming we get the email, and password is handled by them SSO
  };

  return (
    <FullScreenLayout
      alignItems="flex-start"
      gap={24}
      px={16}
      py={32}
      width="100%"
    >
      <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontSize={26} fontWeight="600" fontFamily="Inter, Helvetica, Arial, sans-serif">
        Create your account
      </Text>
      <YStack gap={8} width="100%">
        <Label fontSize={13} fontWeight="600" width="100%">
          Email
        </Label>
        <FormInput
          controllerProps={{
            control,
            name: "email",
          }}
          inputProps={{
            backgroundColor: "#F5F5F5",
            borderRadius: 4,
            borderWidth: 0,
            focusStyle: { outlineStyle: "none" },
            keyboardType: "email-address",
            minHeight: 44,
            placeholder: "What's your email?",
            placeholderTextColor: "#B0B0B0",
            px: 12,
            py: 13,
            size: "$4",
            width: "100%",
            // onChangeText={debounce(setEmail, 500)}
          }}
        />
      </YStack>
      <Button
        alignItems="center"
        backgroundColor="#000"
        borderRadius={4}
        height={44}
        justifyContent="center"
        width="100%"
        pressStyle={{ opacity: 0.5 }}
        focusStyle={{ borderWidth: 0, outlineWidth: 1 }}
        onPress={handleSubmit(onContinueWithEmail)}
      >
        <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="#FFF" fontWeight="600">
          Continue with Email
        </Text>
      </Button>
      {/* <XStack alignItems="center" width="100%" gap={6}>
        <Separator borderColor="#CFC9C9" flex={1} />
        <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontSize={17} fontWeight="600">
          Or
        </Text>
        <Separator borderColor="#CFC9C9" flex={1} />
      </XStack> */}
      {/* <YStack width="100%" gap={16}>
        <Button
          alignItems="center"
          backgroundColor="#FFF"
          borderColor="#000"
          borderRadius={4}
          borderWidth={1}
          height={44}
          justifyContent="center"
          width="100%"
          pressStyle={{ opacity: 0.5 }}
          focusStyle={{ borderWidth: 0, outlineWidth: 1 }}
          onPress={() => null}
        >
          <XStack alignItems="center" gap={8}>
            <Ionicons
              name="logo-google"
              size={17}
              // TODO: add colour logo
            />
            <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="#000" fontWeight="600">
              Continue with Google
            </Text>
          </XStack>
        </Button>
        <Button
          alignItems="center"
          backgroundColor="#FFF"
          borderColor="#000"
          borderRadius={4}
          borderWidth={1}
          height={44}
          justifyContent="center"
          width="100%"
          pressStyle={{ opacity: 0.5 }}
          focusStyle={{ borderWidth: 0, outlineWidth: 1 }}
          onPress={() => null}
        >
          <XStack alignItems="center" gap={8}>
            <Ionicons
              name="logo-facebook"
              size={17}
              // TODO: add colour logo
            />
            <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="#000" fontWeight="600">
              Continue with Facebook
            </Text>
          </XStack>
        </Button>
      </YStack> */}
    </FullScreenLayout>
  );
}
