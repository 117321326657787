import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import localStore from "../../utility/secureStore";
import reduxConstants from "../reduxConstants";
import { AppUser } from "../../types";
import { Platform } from "react-native";

export const USER_DATA_KEY = "foodx_user";

const initialState: { data: AppUser | null } = {
  data: null,
};

const slice = createSlice({
  name: reduxConstants.USER.NAME,
  initialState,
  reducers: {
    setUser: (state, { payload: user }: PayloadAction<AppUser>) => {
      state.data = user || null;
      if (Platform.OS !== "web") {
        // not safe on web
        localStore.set(USER_DATA_KEY, JSON.stringify(user));
      }
    },
    unsetUser: (state) => {
      state.data = null;
      localStore.delete(USER_DATA_KEY);
    },
  },
});

export const { setUser, unsetUser } = slice.actions;

export default slice.reducer;
