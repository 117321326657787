import FullScreenLayout from "../../layouts/FullScreenLayout";
import {
  Button,
  Circle,
  Label,
  Separator,
  Spinner,
  Stack,
  Text,
  View,
  XStack,
  YStack,
} from "tamagui";
import Ionicons from "@expo/vector-icons/Ionicons";
import { Image } from "expo-image";
import {
  AntDesign,
  Feather,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import forkAndSpoon from "../../../assets/images/fork-spoon.png";
import {
  useGetRecipeQuery,
  useGenerateRecipeInstructionsMutation,
  useSaveRecipeMutation,
} from "../../redux/api/api-slice";
import { isErrorResponse } from "../../utility/generalFunctions";
import { useAppSelector } from "../../redux/hooks";
import { useState } from "react";
import FadeInView from "../../components/FadeInView";

function RecipeIngredientCard({ ingredient }) {
  return (
    <XStack gap={8} alignItems="center" py={16}>
      {Math.random() < 0.3 ? ( // TODO: check if ingredient is available in shelf
        <Text
          fontFamily="Inter, Helvetica, Arial, sans-serif"
          color="#B0B0B0"
          fontSize={16}
        >
          ⦸
        </Text>
      ) : (
        <Ionicons name="checkmark" size={16} color="#B0B0B0" />
      )}

      <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontSize={17}>
        {typeof ingredient === "string"
          ? ingredient
          : ingredient.ingredient_name}
      </Text>
    </XStack>
  );
}

function InstructionStep({ stepData }: any) {
  const isStepNumberAUsefulNumber =
    !isNaN(stepData.step_number) && stepData.step_number > 0;
  return (
    <FadeInView
      fadeProps={{
        duration: isStepNumberAUsefulNumber ? stepData.step_number * 300 : 300,
      }}
    >
      <YStack gap={4} py={8} width="100%">
        <Label>
          {stepData.step_number}. {stepData.step_title}
        </Label>
        <Text fontFamily="Inter, Helvetica, Arial, sans-serif">
          {stepData.description}
        </Text>
      </YStack>
    </FadeInView>
  );
}

export default function MealDetailsScreen({ navigation, route }) {
  const {
    data: recipe = route.params?.recipe,
    refetch,
    isFetching,
  } = useGetRecipeQuery({
    recipeId: route.params?.recipe?.id,
  });
  const [saveRecipe] = useSaveRecipeMutation();
  const [generateRecipeInstructions, { isLoading, error }] =
    useGenerateRecipeInstructionsMutation();
  const { savedRecipes } = useAppSelector((state) => state.recipe);
  const [showFullInstructions, setShowFullInstructions] = useState(false);
  const isSaved = recipe?.is_saved_by_user ?? false;
  const recipesGeneratingInstructions = useAppSelector(
    (state) => state.recipe.recipesGeneratingInstructions
  );

  const isCurrentRecipeLoading = recipesGeneratingInstructions.includes(
    recipe.id
  );
  console.log({
    recipesGeneratingInstructions,
    isCurrentRecipeLoading,
    recipeId: recipe.id,
  });

  const originalIngredients = typeof recipe?.ingredients === "string"
  ? recipe.ingredients
      .split(",")
      .map((ingredient: string) => ingredient.trim())
  : recipe.ingredients ?? [];

  const additionalIngredients = typeof recipe?.add_ingredients === "string"
  ? recipe.add_ingredients
      .split(",")
      .map((ingredient: string) => ingredient.trim())
  : recipe.add_ingredients ?? [];

  const splitIngredients = [...originalIngredients, ...additionalIngredients];

  const {
    image,
    duration,
    ingredients,
    cooking_method,
    nutritional_summary,
    numberOfIngredients,
    rating,
    name,
  } = recipe;

  const hasValidRecipeInstructions = !!(
    recipe.instructions &&
    Array.isArray(recipe.instructions) &&
    recipe.instructions.length
  );

  const onNavigateBack = () => {
    navigation.goBack();
  };

  const onOpenOptions = () => {
    console.log("TODO: Recipe options");
  };

  const onSaveRecipe = async () => {
    const response = await saveRecipe({ recipeId: recipe.id });
    if (isErrorResponse(response)) {
      throw response.error;
    }
    refetch();
  };

  const onGetInstructions = async () => {
    const response = await generateRecipeInstructions({ id: recipe.id });
    if (isErrorResponse(response)) {
      throw response.error;
    }
    refetch();
  };

  const onToggleFullInstructions = () => {
    setShowFullInstructions(!showFullInstructions);
  };

  return (
    <FullScreenLayout>
      <YStack width="100%">
        <Stack height={280} position="relative">
          <Image
            style={{
              height: 280,
              width: "100%",
              backgroundColor: "#EFEFEF",
              position: "absolute",
            }}
            source={{ uri: image || route.params?.recipe?.images || "" }}
            placeholder={
              image || route.params?.recipe?.images ? undefined : forkAndSpoon
            }
            contentFit="cover"
            transition={600}
          />
          <XStack width="100%" px={16} py={16} justifyContent="space-between">
            <Circle
              backgroundColor="rgba(255, 255, 255, 0.6)"
              pressStyle={{ opacity: 0.5 }}
              onPress={onNavigateBack}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={24}
              height={24}
            >
              <Ionicons name="chevron-back" size={18} />
            </Circle>
            <Circle
              backgroundColor="rgba(255, 255, 255, 0.6)"
              pressStyle={{ opacity: 0.5 }}
              onPress={onOpenOptions}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={24}
              height={24}
            >
              <Ionicons name="ellipsis-horizontal" size={18} />
            </Circle>
          </XStack>
        </Stack>
        <YStack px={16} py={16} width="100%" gap={8}>
          <Text fontFamily="Inter, Helvetica, Arial, sans-serif">
            ★ {Number(rating).toFixed(1)}
          </Text>
          <Text
            fontFamily="Inter, Helvetica, Arial, sans-serif"
            fontSize={21} /*fontWeight="600"*/
          >
            {name}
          </Text>
          <XStack width="100%" justifyContent="space-around" paddingTop={36}>
            <YStack
              alignItems="center"
              pressStyle={{ opacity: 0.5 }}
              onPress={() => console.log("TODO: Mark 'made'")}
            >
              <Ionicons name="heart-outline" size={20} color="black" />
              <Text fontFamily="Inter, Helvetica, Arial, sans-serif">
                000 Likes
              </Text>
            </YStack>
            <YStack
              alignItems="center"
              pressStyle={{ opacity: 0.5 }}
              onPress={onSaveRecipe}
            >
              <Ionicons
                name={isSaved ? "bookmark" : "bookmark-outline"}
                size={20}
                color="black"
              />
              <Text
                fontFamily="Inter, Helvetica, Arial, sans-serif"
                color={isSaved ? "gray" : "black"}
              >
                {isSaved ? "Saved!" : "Bookmark"}
              </Text>
            </YStack>
            <YStack
              alignItems="center"
              pressStyle={{ opacity: 0.5 }}
              onPress={() => console.log("TODO: Recipe details")}
            >
              <Ionicons name="share-social-outline" size={20} color="black" />
              <Text fontFamily="Inter, Helvetica, Arial, sans-serif">
                Share
              </Text>
            </YStack>
          </XStack>
        </YStack>
        <Separator borderColor="#D9D9D9" />
        <YStack px={16} py={16}>
          <Text
            fontFamily="Inter, Helvetica, Arial, sans-serif"
            fontSize={17}
            fontWeight="700"
            paddingTop={24}
          >
            Prep Summary
          </Text>
          <XStack
            width="100%"
            alignItems="flex-start"
            justifyContent="space-between"
            paddingTop={24}
            px={16}
          >
            <YStack>
              <Text
                fontFamily="Inter, Helvetica, Arial, sans-serif"
                fontSize={14}
                fontWeight="700"
              >
                SERVING SIZE
              </Text>
              <XStack alignItems="center" gap={8} paddingTop={8}>
                <Feather name="users" size={22} color="black" />
                <Text
                  fontFamily="Inter, Helvetica, Arial, sans-serif"
                  flex={1}
                  fontSize={17}
                  fontWeight="400"
                >
                  {recipe?.serving_size ?? '-'}
                </Text>
              </XStack>
            </YStack>

            <YStack>
              <Text
                fontFamily="Inter, Helvetica, Arial, sans-serif"
                fontSize={14}
                fontWeight="700"
              >
                TIME
              </Text>
              <XStack alignItems="center" gap={8} paddingTop={8}>
                <AntDesign name="clockcircleo" size={18} color="black" />
                <Text
                  fontFamily="Inter, Helvetica, Arial, sans-serif"
                  flex={1}
                  fontSize={17}
                  fontWeight="400"
                >
                  {duration}
                </Text>
              </XStack>
            </YStack>
          </XStack>
          <XStack
            width="100%"
            alignItems="flex-start"
            justifyContent="space-between"
            py={24}
            px={16}
          >
            <YStack>
              <Text
                fontFamily="Inter, Helvetica, Arial, sans-serif"
                fontSize={14}
                fontWeight="700"
              >
                METHOD
              </Text>
              <XStack alignItems="center" gap={8} paddingTop={8}>
                <MaterialCommunityIcons
                  name="chef-hat"
                  size={22}
                  color="black"
                />

                <Text
                  fontFamily="Inter, Helvetica, Arial, sans-serif"
                  flex={1}
                  fontSize={17}
                  fontWeight="400"
                >
                  {cooking_method}
                </Text>
              </XStack>
            </YStack>

            {/* <div style={{ paddingRight: "25%" }}>
              <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontSize={14} fontWeight="700">
                DIFFICULTY
              </Text>
              <XStack alignItems="center" gap={8} paddingTop={8}>
                <MaterialIcons name="network-check" size={22} color="black" />
                <Text fontFamily="Inter, Helvetica, Arial, sans-serif" flex={1} fontSize={17} fontWeight="400">
                  Medium
                </Text>
              </XStack>
            </div> */}
          </XStack>

          <Separator borderColor="#D9D9D9" />

          <YStack py={24}>
            <Text
              fontFamily="Inter, Helvetica, Arial, sans-serif"
              fontSize={17}
              fontWeight="600"
            >
              Ingredients
            </Text>
            <YStack gap={6} paddingTop={12}>
              {splitIngredients?.map((ingredient) => {
                return (
                  <Text
                    fontFamily="Inter, Helvetica, Arial, sans-serif"
                    fontSize={17}
                    fontWeight="400"
                  >
                    {ingredient}
                  </Text>
                );
              })}
              <Text
                fontFamily="Inter, Helvetica, Arial, sans-serif"
                fontSize={17}
                fontWeight="400"
              >
                {/* TODO: GET THIS PART FROM REQUEST */}
              </Text>
              {/* <View
                style={{ marginTop: "24px" }}
                display="flex"
                flex={1}
                width={"100%"}
                alignItems="center"
                justifyContent="center"
                borderWidth={1}
                borderRadius={4}
                py={13}
              >
                <Text fontFamily="Inter, Helvetica, Arial, sans-serif">
                  Add to Shopping List
                </Text>
              </View> */}
            </YStack>
          </YStack>
          <Separator borderColor="#D9D9D9" />

          <YStack py={24}>
            <Text
              fontFamily="Inter, Helvetica, Arial, sans-serif"
              fontSize={17}
              fontWeight="600"
            >
              Nutritional Summary
            </Text>
            <YStack paddingTop={12}>
              <Text
                fontFamily="Inter, Helvetica, Arial, sans-serif"
                fontSize={17}
                fontWeight="400"
              >
                {nutritional_summary}
              </Text>
            </YStack>
          </YStack>

          <Separator borderColor="#D9D9D9" />

          <YStack justifyContent="space-between" width={"95%"} py={24}>
            <XStack justifyContent="space-between">
              <Text
                fontFamily="Inter, Helvetica, Arial, sans-serif"
                fontSize={17}
                fontWeight="600"
              >
                Instructions
              </Text>
              <Text
                fontFamily="Inter, Helvetica, Arial, sans-serif"
                fontSize={17}
                fontWeight="400"
              >
                {hasValidRecipeInstructions && (
                  <Text fontFamily="Inter, Helvetica, Arial, sans-serif">
                    ({recipe.instructions.length} steps)
                  </Text>
                )}
              </Text>
            </XStack>
            {hasValidRecipeInstructions ? (
              <>
                <YStack paddingTop={12}>
                  {/* TODO: render as text */}
                  {/* {JSON.stringify(recipe.instructions)} */}
                  {recipe.instructions
                    .filter(
                      (step: any, i: number) => showFullInstructions || i < 2
                    )
                    .map((step: any) => {
                      return (
                        <InstructionStep
                          key={step.step_number}
                          stepData={step}
                        />
                      );
                    })}
                </YStack>
                <View
                  style={{ marginTop: "24px" }}
                  display="flex"
                  flex={1}
                  width={"100%"}
                  alignItems="center"
                  justifyContent="center"
                  borderWidth={1}
                  borderRadius={4}
                  py={13}
                  pressStyle={{ opacity: 0.5 }}
                  onPress={onToggleFullInstructions}
                >
                  <Text fontFamily="Inter, Helvetica, Arial, sans-serif">
                    {showFullInstructions ? "Hide" : "Show"} full instructions
                  </Text>
                </View>
              </>
            ) : (
              <View
                display="flex"
                backgroundColor="white"
                marginTop={24}
                flex={1}
                width={"100%"}
                alignItems="center"
                justifyContent="center"
                borderColor="black"
                borderWidth={1}
                borderRadius={4}
                py={13}
                pressStyle={{ opacity: 0.5 }}
                onPress={onGetInstructions}
                disabled={isCurrentRecipeLoading /* || isFetching*/}
              >
                {isCurrentRecipeLoading /* || isFetching*/ ? (
                  <Spinner color="black" />
                ) : (
                  <Text fontFamily="Inter, Helvetica, Arial, sans-serif">
                    Get instructions
                  </Text>
                )}
              </View>
            )}
          </YStack>
        </YStack>
      </YStack>
    </FullScreenLayout>
  );
}
