import { XStack, YStack } from "tamagui";
import { UIText } from "../../components";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { MaterialIcons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
export default function PromptDetailsScreen({ route }) {
  const { prompt } = route.params || {};

  console.log("PROMPT", prompt);
  return (
    <FullScreenLayout paddingTop={16}>
      <YStack width="100%" gap={18} px={16} backgroundColor="white">
        <UIText fontSize={21} fontWeight={700}>
          Info we used
        </UIText>

        <XStack
          width="100%"
          alignItems="flex-start"
          justifyContent="space-between"
          paddingTop={10}
        >
          <div>
            <UIText fontSize={14} fontWeight={700}>
              MEAL TYPE
            </UIText>
            <XStack gap={4} paddingTop={8}>
              <MaterialIcons name="dinner-dining" size={22} color="black" />
              <UIText flex={1} fontSize={17} fontWeight={400}>
                {prompt.mealType}
              </UIText>
            </XStack>
          </div>

          <div style={{ paddingRight: "15%" }}>
            <UIText fontSize={14} fontWeight={700}>
              TIME
            </UIText>
            <XStack gap={4} paddingTop={8}>
              <AntDesign name="clockcircleo" size={18} color="black" />
              <UIText flex={1} fontSize={17} fontWeight={400}>
                {prompt.availableTime}
              </UIText>
            </XStack>
          </div>
        </XStack>

        <YStack borderTopColor={"#D9D9D9"} borderTopWidth={1} paddingTop={24}>
          <UIText fontSize={14} fontWeight={700}>
            AVAILABLE INGREDIENTS
          </UIText>
          <YStack paddingTop={12}>
            {prompt.availableIngredients?.map((ingredient) => {
              return (
                <UIText fontSize={17} fontWeight={400}>
                  {ingredient}
                </UIText>
              );
            })}
          </YStack>
        </YStack>

        <XStack
          width="100%"
          alignItems="flex-start"
          justifyContent="space-between"
          borderTopColor={"#D9D9D9"}
          borderTopWidth={1}
          paddingTop={24}
        >
          <div>
            <UIText fontSize={14} fontWeight={700}>
              SERVING SIZE
            </UIText>
            <XStack gap={4} paddingTop={8}>
              <MaterialIcons name="dinner-dining" size={22} color="black" />
              <UIText flex={1} fontSize={17} fontWeight={400}>
                {/* TODO: GET THIS PART FROM REQUEST */}
              </UIText>
            </XStack>
          </div>

          <div style={{ paddingRight: "19%" }}>
            <UIText fontSize={14} fontWeight={700}>
              METHOD
            </UIText>
            <XStack gap={4} paddingTop={8}>
              <AntDesign name="clockcircleo" size={18} color="black" />
              <UIText flex={1} fontSize={17} fontWeight={400}>
                {prompt.preferredCookingMethod}
              </UIText>
            </XStack>
          </div>
        </XStack>

        <YStack borderTopColor={"#D9D9D9"} borderTopWidth={1} paddingTop={24}>
          <UIText fontSize={14} fontWeight={700}>
            ADDITIONAL INGREDIENTS
          </UIText>
          <YStack paddingTop={12}>
            <UIText fontSize={17} fontWeight={400}>
              {/* TODO: GET THIS PART FROM REQUEST */}A handful is fine
            </UIText>
            <UIText fontSize={14} fontWeight={400} color="#646464">
              {prompt.opennessToAdditionalIngredients}
            </UIText>
          </YStack>
        </YStack>

        <YStack borderTopColor={"#D9D9D9"} borderTopWidth={1} paddingTop={24}>
          <UIText fontSize={14} fontWeight={700}>
            COMMENT
          </UIText>
          <YStack paddingTop={12}>
            <UIText fontSize={17} fontWeight={400}>
              {prompt.additionalComments}
            </UIText>
          </YStack>
        </YStack>
      </YStack>
    </FullScreenLayout>
  );
}
