import FullScreenLayout from "../../layouts/FullScreenLayout";
import { UIText } from "../../components";
import { Circle, Separator, Stack, XStack, YStack } from "tamagui";
import Ionicons from "@expo/vector-icons/Ionicons";
import { Image } from "expo-image";
import { FlatList } from "react-native";

function RecipeIngredientCard({ ingredient }) {
  return (
    <XStack gap={8} alignItems="center" py={16}>
      {Math.random() < 0.3 ? ( // TODO: check if ingredient is available in shelf
        <UIText color="#B0B0B0" fontSize={16}>
          ⦸
        </UIText>
      ) : (
        <Ionicons name="checkmark" size={16} color="#B0B0B0" />
      )}

      <UIText fontSize={17}>
        {typeof ingredient === "string"
          ? ingredient
          : ingredient.ingredient_name}
      </UIText>
    </XStack>
  );
}

export default function RecipeScreen({ navigation, route }) {
  const { recipe } = route.params;

  console.log("recipe", recipe);

  const splitIngredients =
    typeof recipe?.ingredients === "string"
      ? recipe.ingredients.split(",").map((ingredient) => ingredient.trim())
      : recipe.ingredients ?? [];

  const { image, ingredients, numberOfIngredients, rating, name } = recipe;

  const onNavigateBack = () => {
    navigation.goBack();
  };

  const onOpenOptions = () => {
    console.log("TODO: Recipe options");
  };

  return (
    <FullScreenLayout>
      <YStack width="100%">
        <Stack height={280} position="relative">
          <Image
            style={{
              height: 280,
              width: "100%",
              backgroundColor: "#EFEFEF",
              position: "absolute",
            }}
            source={{ uri: image }}
            // placeholder={blurhash}
            contentFit="cover"
            transition={600}
          />
          <XStack width="100%" px={16} py={16} justifyContent="space-between">
            <Circle
              backgroundColor="rgba(255, 255, 255, 0.6)"
              pressStyle={{ opacity: 0.5 }}
              onPress={onNavigateBack}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={24}
              height={24}
            >
              <Ionicons name="chevron-back" size={18} />
            </Circle>
            <Circle
              backgroundColor="rgba(255, 255, 255, 0.6)"
              pressStyle={{ opacity: 0.5 }}
              onPress={onOpenOptions}
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={24}
              height={24}
            >
              <Ionicons name="ellipsis-horizontal" size={18} />
            </Circle>
          </XStack>
        </Stack>
        <YStack px={16} py={16} width="100%" gap={8}>
          <UIText>★ {Number(rating).toFixed(1)}</UIText>
          <UIText fontSize={21} /*fontWeight={600}*/>{name}</UIText>
          <XStack width="100%" justifyContent="space-around" paddingTop={36}>
            <YStack
              alignItems="center"
              pressStyle={{ opacity: 0.5 }}
              onPress={() => console.log("TODO: Mark 'made'")}
            >
              <Ionicons name="checkmark" size={20} />
              <UIText>Mark "made"</UIText>
            </YStack>
            <YStack
              alignItems="center"
              pressStyle={{ opacity: 0.5 }}
              onPress={() => console.log("TODO: Remove")}
            >
              <Ionicons name="close" size={20} />
              <UIText>Remove</UIText>
            </YStack>
            <YStack
              alignItems="center"
              pressStyle={{ opacity: 0.5 }}
              onPress={() => console.log("TODO: Recipe details")}
            >
              <Ionicons name="arrow-forward" size={20} />
              <UIText>Recipe details</UIText>
            </YStack>
          </XStack>
        </YStack>
        <Separator borderColor="#D9D9D9" />
        <YStack px={16} py={24} gap={24}>
          <UIText color="#4D4C4C">{numberOfIngredients} Ingredients</UIText>
          <FlatList
            data={splitIngredients}
            renderItem={({ item }) => (
              <RecipeIngredientCard ingredient={item} />
            )}
            keyExtractor={(item) => item}
            ItemSeparatorComponent={
              <Separator borderBottomWidth={1} borderColor="#D9D9D9" />
            }
          />
        </YStack>
      </YStack>
    </FullScreenLayout>
  );
}
