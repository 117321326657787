import { Stack, XStack, YStack } from "tamagui";
import { fillLeftToLength } from "../../utility/generalFunctions";
import { UIText } from "../../components/UIText";
import { customLocale } from "../../utility/dateFunctions";
import { formatDistanceToNow } from "date-fns";

export default function PreviousPromptsCard({ number, date, description }) {
  return (
    <XStack
      alignItems="center"
      gap={6}
      py={16}
      // backgroundColor="#ff9999"
      paddingRight={16}
    >
      <Stack
        borderWidth={1}
        borderRadius="100%"
        borderColor="#585858"
        width={24}
        height={24}
        alignItems="center"
        justifyContent="center"
      >
        <UIText fontSize={9} fontWeight={700} color="#585858">
          {fillLeftToLength(number, "0", 2)}
        </UIText>
      </Stack>
      <YStack width="100%">
        <UIText color="#4D4C4C" fontSize={11}>
          {formatDistanceToNow(new Date(date), {
            addSuffix: true,
            locale: customLocale,
          })}
        </UIText>
        <XStack width="100%">
          <UIText numberOfLines={2} ellipsizeMode="tail">
            {description}
          </UIText>
        </XStack>
      </YStack>
    </XStack>
  );
}
