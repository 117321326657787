import { XStack, YStack } from "tamagui";
import { UIText } from "../../components";
import Ionicons from "@expo/vector-icons/Ionicons";
import { TouchableOpacity } from "react-native";

const availableTimes = [
  { value: "10 - 20", promptValue: "10 - 20 minutes" },
  { value: "20 - 30", promptValue: "20 - 30 minutes" },
  { value: "30 - 40", promptValue: "30 - 40 minutes" },
  { value: "40 - 50", promptValue: "40 - 50 minutes" },
  { value: "50 - 60", promptValue: "50 - 60 minutes" },
  { value: "+ 60", promptValue: "over 60 minutes" },
  { value: Infinity, promptValue: "an endless amount" },
];

function AvailableTimeOption({ availableTime, isSelected }) {
  const isInfinite = availableTime.value === Infinity;
  return (
    <YStack
      alignItems="center"
      gap={3}
      width="100%"
      borderWidth={1}
      borderRadius={4}
      px={16}
      py={12}
      backgroundColor={isSelected ? "#EFEFEF" : undefined} // TODO: what color should selection be?
    >
      <UIText fontSize={26} color="#636363">
        {isInfinite ? (
          <Ionicons name="infinite-outline" color="#636363" size={30} />
        ) : (
          availableTime.value
        )}
      </UIText>
      <UIText>{isInfinite ? "Unlimited" : "Mins"}</UIText>
    </YStack>
  );
}

export default function AvailableTime({ promptData, setGeneratePrompt }) {
  const selectedAvailableTime = promptData.availableTime;

  const onSelect = (newSelectedAvailableTime) => {
    setGeneratePrompt((prev) => ({
      ...prev,
      availableTime: newSelectedAvailableTime,
    }));
  };

  return (
    <YStack alignItems="center" gap={36}>
      <UIText fontSize={21}>
        How much time do you have to prepare this meal?
      </UIText>
      <XStack gap={12} width="100%" flexWrap="wrap">
        {availableTimes.map((availableTime) => {
          const isSelected =
            availableTime.promptValue === selectedAvailableTime;
          return (
            <TouchableOpacity
              key={availableTime}
              onPress={() => onSelect(availableTime.promptValue)}
              style={{ width: "45%" }}
            >
              <AvailableTimeOption
                availableTime={availableTime}
                isSelected={isSelected}
              />
            </TouchableOpacity>
          );
        })}
      </XStack>
    </YStack>
  );
}
