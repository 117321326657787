import { Spinner, XStack, YStack } from "tamagui";
import { UIText } from "../../components";
import { TouchableOpacity } from "react-native";
import { IOnboardingData } from "../../types";
import { useGetDietaryRequiremnetsQuery } from "../../redux/api/api-slice";

interface IOption {
  requirement: string;
  isSelected: boolean;
}

interface IProps {
  userOnboardingData: IOnboardingData;
  setUserOnboardingData: React.Dispatch<React.SetStateAction<IOnboardingData>>;
}

function DietaryRequirementOption({ requirement, isSelected }: IOption) {
  return (
    <XStack
      alignItems="center"
      justifyContent="center"
      flexGrow={0}
      flexShrink={1}
      gap={16}
      borderWidth={1}
      borderRadius={4}
      px={26}
      py={12}
      backgroundColor={isSelected ? "#EFEFEF" : undefined} // TODO: what color should selection be?
    >
      <UIText fontWeight="600">{requirement}</UIText>
    </XStack>
  );
}

export default function DietaryRequirements({
  userOnboardingData,
  setUserOnboardingData,
}: IProps) {
  const selectedDietaryRequirements = userOnboardingData.dietaryRequirements;
  const { data: dietaryRequirements = [], isLoading } =
    useGetDietaryRequiremnetsQuery();
  console.log({ dietaryRequirements });

  const onSelect = (newSelectedDietaryRequirement: string) => {
    setUserOnboardingData((prev) => {
      let newDietaryRequirements = prev.dietaryRequirements;
      if (newSelectedDietaryRequirement.toLowerCase() === "none") {
        return {
          ...prev,
          dietaryRequirements: [newSelectedDietaryRequirement],
        };
      }
      if (newDietaryRequirements.includes("None")) {
        // TODO: may need to make constants for dietaryRequirementOptions
        newDietaryRequirements.splice(
          newDietaryRequirements.indexOf("None"),
          1
        );
      }
      if (prev.dietaryRequirements.includes(newSelectedDietaryRequirement)) {
        newDietaryRequirements.splice(
          newDietaryRequirements.indexOf(newSelectedDietaryRequirement),
          1
        );
      } else {
        newDietaryRequirements.push(newSelectedDietaryRequirement);
      }
      return { ...prev, dietaryRequirements: newDietaryRequirements };
    });
  };

  return (
    <YStack gap={36}>
      <YStack gap={8}>
        <UIText fontSize={21} fontWeight="600" maxWidth="70%">
          Do you have any dietary requirements?
        </UIText>
        <UIText color="#4D4C4C">Meals generated will best fit your diet</UIText>
      </YStack>

      <XStack flexWrap="wrap" gap={8} width="100%">
        <YStack py={12}>
          <UIText color="#303030">
            Please select all the options that apply to you:
          </UIText>
        </YStack>
        {isLoading ? (
          <YStack width="100%" py={16} alignItems="center">
            <Spinner color="black" />
          </YStack>
        ) : (
          <>
            {[...dietaryRequirements, { requirement: "None" }].map((item) => {
              const { requirement } = item;
              const isSelected =
                selectedDietaryRequirements.includes(requirement);
              return (
                <TouchableOpacity
                  key={requirement}
                  onPress={() => onSelect(requirement)}
                >
                  <DietaryRequirementOption
                    requirement={requirement}
                    isSelected={isSelected}
                  />
                </TouchableOpacity>
              );
            })}
          </>
        )}
      </XStack>
    </YStack>
  );
}
