import { XStack, YStack } from "tamagui";
import { UIText } from "../../components";
import Ionicons from "@expo/vector-icons/Ionicons";
import { TouchableOpacity } from "react-native";

const iconSize = 20;

const mealTypes = [
  {
    name: "Breakfast / Brunch",
    icon: <Ionicons name="cafe-outline" color="#636363" size={iconSize} />,
    promptValue: "breakfast/brunch",
  },
  {
    name: "Lunch",
    icon: <Ionicons name="fast-food-outline" color="#636363" size={iconSize} />,
    promptValue: "lunch",
  },
  {
    name: "Snack",
    icon: <Ionicons name="fast-food-outline" color="#636363" size={iconSize} />,
    promptValue: "a snack",
  },
  {
    name: "Dinner",
    icon: (
      <Ionicons name="restaurant-outline" color="#636363" size={iconSize} />
    ),
    promptValue: "dinner",
  },
  {
    name: "Dessert",
    icon: <Ionicons name="ice-cream-outline" color="#636363" size={iconSize} />,
    promptValue: "dessert",
  },
];

function MealTypeOption({ name, icon, isSelected }) {
  return (
    <XStack
      alignItems="center"
      gap={16}
      width="100%"
      borderWidth={1}
      borderRadius={4}
      px={16}
      py={12}
      backgroundColor={isSelected ? "#EFEFEF" : undefined} // TODO: what color should selection be?
    >
      {icon}
      <UIText>{name}</UIText>
    </XStack>
  );
}

export default function MealType({ promptData, setGeneratePrompt }) {
  const selectedMealType = promptData.mealType;

  const onSelect = (newSelectedMealType) => {
    setGeneratePrompt((prev) => ({
      ...prev,
      mealType: newSelectedMealType,
    }));
  };

  return (
    <YStack alignItems="center" gap={36}>
      <UIText fontSize={21}>What type of meal are you looking to make?</UIText>
      <YStack gap={12} width="100%">
        {mealTypes.map((mealType) => {
          const isSelected = mealType.promptValue === selectedMealType;
          return (
            <TouchableOpacity
              key={mealType.name}
              onPress={() => onSelect(mealType.promptValue)}
            >
              <MealTypeOption
                name={mealType.name}
                icon={mealType.icon}
                isSelected={isSelected}
              />
            </TouchableOpacity>
          );
        })}
      </YStack>
    </YStack>
  );
}
