import FullScreenLayout from "../../layouts/FullScreenLayout";
import { Square, XStack, YStack } from "tamagui";
import { UIText } from "../../components";
import Ionicons from "@expo/vector-icons/Ionicons";
import IngredientCard from "../../components/ingredients/IngredientsCard";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "../../redux/hooks";

export default function ShoppingListScreen() {
  const shoppingList = useAppSelector(state => state.ingredients.shoppingList);
  const { navigate } = useNavigation();
  const onCardPress = (params) => {
    // TODO...
    console.log("Card press", params);
    navigate("IngredientRecipes", params);
  };

  const onGoToShoppingCart = () => {
    navigate("ShoppingCart");
  };

  const onGoToAddToShoppingCart = () => {
    navigate("AddToShoppingCart");
  };

  return (
    <FullScreenLayout flex={1} alignItems="start" paddingTop={24} px={16}>
      <XStack
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        marginBottom={20}
        minHeight={25}
      >
        <UIText fontSize={21} fontWeight={500}>
          Shop List
        </UIText>
        <XStack alignItems="center" gap={6}>
          <Square
            backgroundColor="#EAEAEA"
            borderRadius={6}
            padding={6}
            pressStyle={{ opacity: 0.5 }}
            onPress={onGoToAddToShoppingCart}
          >
            <Ionicons name="add-outline" size={20} />
          </Square>
          <Square
            backgroundColor="#EAEAEA"
            borderRadius={6}
            padding={6}
            pressStyle={{ opacity: 0.5 }}
            onPress={onGoToShoppingCart}
          >
            <Ionicons name="cart-outline" size={20} />
          </Square>
        </XStack>
      </XStack>
      <UIText color="#4D4C4C" marginBottom={24}>
        All ingredients added from recipes that you need to get/add to ‘My
        Shelf’
      </UIText>
      <UIText color="#4D4C4C" fontSize={12} marginBottom={16}>
        {shoppingList.length} Ingredients in shop
      </UIText>
      <YStack width="100%" gap={8}>
        {shoppingList
          .sort((a, b) => (a > b ? 1 : -1))
          .map((ingredient) => {
            return (
              <IngredientCard
                key={ingredient}
                name={ingredient}
                title={ingredient}
                onCardPress={onCardPress}
              />
            );
          })}
      </YStack>
    </FullScreenLayout>
  );
}
