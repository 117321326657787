import { z } from "zod";

export interface IOnboardingData {
  dietaryRequirements: string[];
  experience: string;
  servingSize: number | null;
}

export const UserSchema = z.object({
  id: z.string().or(z.number()), // TODO: should probably decide which one it is between number or string
  username: z.string(),
  email: z.string().email(),
  serving_size: z.onumber().or(z.ostring()),
  cooking_experience: z.ostring(),
  dietary_requirements: z
    .array(
      z.object({
        id: z.number(),
        requirement: z.string(),
      })
    )
    .optional(),
  updated_at: z.string().datetime(),
  created_at: z.string().datetime(),
  // app props
  mustDoOnboarding: z.oboolean(), // maybe can be otherwise handled as a column in user DB table?
});

export type AppUser = z.infer<typeof UserSchema>;

export const UserTokenSchema = z.object({
  token: z.string(),
  token_type: z.string(),
});

export type AppUserToken = z.infer<typeof UserTokenSchema>;
