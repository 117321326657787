import { ScrollView, Separator, Spacer, Square, Tabs, XStack } from "tamagui";
import { UIText } from "../../components";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { useEffect, useState } from "react";

import PromptDetailsScreen from "./PromptDetails";
import RecipeRecommendationsScreen from "./RecipeRecommendations";
import Ionicons from "@expo/vector-icons/Ionicons";

function TabView({ name, title, tabState }) {
  return (
    <Tabs.Tab
      flex={1}
      height={27}
      value={name}
      borderBottomWidth={tabState.currentTab === name ? 2 : 0}
      borderColor="#303030"
    >
      <UIText
        fontWeight={600}
        color={tabState.currentTab === name ? "black" : "#4D4C4C"}
      >
        {title}
      </UIText>
    </Tabs.Tab>
  );
}

export default function RecommendationsScreen({ route }) {
  const { toTab = "recipes", recipes } = route?.params || {};

  const [tabState, setTabState] = useState({
    activeAt: null,
    currentTab: toTab,
    intentAt: null,
    prevActiveAt: null,
  });

  useEffect(() => {
    setTabState((prev) => ({ ...prev, currentTab: toTab }));
  }, [toTab]);

  const setCurrentTab = (currentTab) =>
    setTabState({ ...tabState, currentTab });

  const { currentTab } = tabState;

  /**
   * TODO:
   * There seems to be a bug(?) with the TabViews where they does not navigate to
   * "shelf" when toTab is set to "shelf" in this scenario:
   * 1. From the "shop" tab navigate to the cart screen
   * 2. Select some ingredient(s) and add it/them to your shelf
   * 3. Should land on "shelf tab". Navigate back to the "shop" tab and repeat step 1 & 2
   * 4. Will now instead land on "shop" tab 🤷‍♂️
   */

  return (
    <FullScreenLayout>
      <XStack justifyContent="space-between" width="100%">
        <Spacer width={100} />
        <UIText py={18} fontWeight={700}>
          FoodX
        </UIText>
        <XStack width={100} padding={8} gap={16} justifyContent="flex-end">
          <Square pressStyle={{ opacity: 0.5 }}>
            <Ionicons name="search" size={20} />
          </Square>
          <Square pressStyle={{ opacity: 0.5 }}>
            <Ionicons name="ellipsis-horizontal" size={20} />
          </Square>
        </XStack>
      </XStack>
      <Tabs
        defaultValue="recipes"
        width={400}
        flexDirection="column"
        value={currentTab}
        onValueChange={setCurrentTab}
        flex={1}
        px={16}
      >
        <Tabs.List paddingHorizontal={16} gap={16}>
          <TabView
            name="recipes"
            title={"(" + recipes.length + ") Recipes"}
            tabState={tabState}
          />
          <TabView name="prompt" title="Prompt Details" tabState={tabState} />
        </Tabs.List>
        <Separator borderColor="#D9D9D9" />
        <ScrollView flex={1}>
          <Tabs.Content value="recipes">
            <RecipeRecommendationsScreen route={route} />
          </Tabs.Content>
          <Tabs.Content value="prompt">
            <PromptDetailsScreen route={route} />
          </Tabs.Content>
        </ScrollView>
      </Tabs>
    </FullScreenLayout>
  );
}
