import { XStack, YStack } from "tamagui";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import * as Progress from "react-native-progress";
import { UIText } from "../../components";
import Ionicons from "@expo/vector-icons/Ionicons";
import FadeInView from "../../components/FadeInView";

// TODO: cleanup props

/**
 * Displays progress of a relatively long running task that a user must await. Completed subtasks can
 * optionally be displayed as they are completed.
 *
 * @prop {number} progress - The progress.
 * @prop {string} taskTitle - A title or heading displayed below the progress UI to provide context about the overarching operation or task being carried out.
 * @prop {Array<{ message: string, completionPercentage: number }>} completedSubtasks - List of sub-tasks with associated completion percentages. Each sub-task has a message which is displayed when the sub-task is completed and a completionPercentage which indicates the progress percentage at which the sub-task should be marked as completed.
 * @prop {function} onCompletion - Callback function triggered when the progress reaches completion (i.e., 100%)
 * @prop {string} errorMessage - Message to display in case of an error during the request. If provided, the progress might be halted, or the UI could change to reflect the error state.
 */
export default function TaskProgressScreen({
  progress,
  taskTitle,
  completedSubtasks = [],
}) {
  return (
    <FullScreenLayout padding={16}>
      <YStack py={48} gap={24}>
        <XStack alignItems="center" justifyContent="center" position="relative">
          <Progress.Circle
            animated
            showsText
            textStyle={{
              fontSize: 26,
              fontWeight: 700,
            }}
            borderWidth={0}
            size={120}
            thickness={5}
            unfilledColor="#D9D9D9"
            color="#303030"
            progress={progress}
            fill="transparent"
          />
        </XStack>
        <UIText fontSize={17} fontWeight={600} px={48} textAlign="center">
          {taskTitle}
        </UIText>
        <YStack gap={8}>
          {completedSubtasks.map((subtask) => {
            return (
              <FadeInView key={subtask} style={{ display: "flex" }}>
                <XStack key={subtask} gap={2}>
                  <Ionicons name="checkmark-outline" color="#000" size={14} />
                  <UIText fontWeight={300} color="black">
                    {subtask}
                  </UIText>
                </XStack>
              </FadeInView>
            );
          })}
        </YStack>
      </YStack>
    </FullScreenLayout>
  );
}
