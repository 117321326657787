import { formatDuration, sub } from "date-fns";
import { customLocale } from "../utility/dateFunctions";

/**
 * The following are convenience types for dev purposes.
 * If you want the editor to tell you what you're missing from an object in the list,
 * uncomment the below interfaces, and uncomment the `IRecipe[]` type at recipes assignment.
 * (Could probably have just used jsdocs but... 🤷‍♂️)
 */

// interface IUser {
//   id?: string;
//   username: string;
//   img: string;
// }

// interface IRecipe {
//   id: string;
//   duration: string;
//   title: string;
//   numberOfIngredients: number;
//   dateAdded: Date;
//   image: string;
//   user: IUser;
//   isMade?: boolean;
//   rating: number;
//   ingredients?: string[];
// }

const dummyRecipes /*: IRecipe[]*/ = [
  {
    id: "1a2b3c4d5e6e",
    duration: formatDuration({ minutes: 30 }, { locale: customLocale }),
    title: "Dairy free blueberry & apricot waffles",
    numberOfIngredients: 10,
    dateAdded: sub(new Date(), { days: 7 }),
    image:
      "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/blueberry-waffles.jpg",
    user: {
      img: "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/user-Isaac3000.jpg",
      username: "@Isaac3000",
    },
    rating: 4.8,
    isMade: false,
    ingredients: [
      "2 cups almond milk",
      "2 cups all-purpose flour",
      "2 tablespoons granulated sugar",
      "1 tablespoon baking powder",
      "1/2 teaspoon salt",
      "1/4 cup vegetable oil or melted coconut oil",
      "1 teaspoon vanilla extract",
      "1 cup fresh blueberries",
      "1/2 cup chopped dried apricots",
      "1 tablespoon lemon zest",
    ],
  },
  {
    id: "7g8h9i0j1k2k",
    dateAdded: sub(new Date(), { months: 2 }),
    duration: formatDuration({ minutes: 45 }, { locale: customLocale }),
    title: "Golden spicy syrup carrot & broccoli noodles",
    user: {
      img: "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/user-Norma_j3an.jpg",
      username: "@Norma_j3an",
    },
    image:
      "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/broccoli-noodles.jpg",
    numberOfIngredients: 16,
    rating: 5.0,
    isMade: true,
    ingredients: [
      "200g rice noodles",
      "1 tablespoon vegetable oil",
      "2 cloves garlic, minced",
      "1 inch ginger, minced",
      "2 medium carrots, julienned",
      "1 head broccoli, broken into small florets",
      "2 tablespoons soy sauce",
      "1 tablespoon chili sauce (adjust to preference)",
      "2 tablespoons maple syrup or agave syrup",
      "1 teaspoon ground turmeric",
      "1/2 teaspoon ground black pepper",
      "2 green onions, chopped",
      "1 tablespoon sesame seeds",
      "1 red chili, sliced (optional)",
      "2 tablespoons fresh cilantro, chopped",
      "Juice of 1 lime",
    ],
  },
  {
    id: "3m4n5o6p7q8q",
    duration: formatDuration(
      { minutes: 20, hours: 1 },
      { locale: customLocale }
    ),
    dateAdded: sub(new Date(), { days: 5 }),
    title: "Spanish rice with squid, prawn & fennel",
    user: {
      img: "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/user-Funtime-chef.jpg",
      username: "@Funtime-chef",
    },
    image:
      "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/seafood-fennel.jpg",
    numberOfIngredients: 24,
    rating: 4.2,
    isMade: false,
    ingredients: [
      "200g paella rice or Arborio rice",
      "500g fresh squid, cleaned and cut into rings",
      "250g prawns, peeled and deveined",
      "2 tablespoons olive oil",
      "1 large onion, finely chopped",
      "3 cloves garlic, minced",
      "1 fennel bulb, thinly sliced",
      "1 red bell pepper, diced",
      "1 yellow bell pepper, diced",
      "4 ripe tomatoes, chopped",
      "750ml chicken or fish stock",
      "1/4 teaspoon saffron threads",
      "1 teaspoon smoked paprika",
      "1/2 teaspoon ground turmeric",
      "1/4 teaspoon cayenne pepper",
      "1 bay leaf",
      "1/2 cup frozen peas",
      "1/4 cup fresh parsley, chopped",
      "1 lemon, zested and juiced",
      "2 tablespoons white wine (optional)",
      "Salt, to taste",
      "Black pepper, to taste",
      "1/4 cup sliced green olives",
      "1/4 cup chopped roasted red peppers",
    ],
  },
  {
    id: "ksjdhfiubvwi",
    duration: formatDuration({ minutes: 45 }, { locale: customLocale }),
    title: "Olive, cauliflower & harissa pasta",
    numberOfIngredients: 12,
    dateAdded: sub(new Date(), { days: 2 }),
    image:
      "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/olive-cauliflower-harissa-pasta.jpg",
    user: {
      username: "JeffTheHuman",
      img: "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/user-Isaac3000.jpg",
    },
    isMade: false,
    rating: 4.3,
    ingredients: [
      "250g pasta of choice (penne or fusilli works well)",
      "2 tablespoons olive oil",
      "1 small head cauliflower, broken into florets",
      "2 cloves garlic, minced",
      "1/4 cup black olives, pitted and sliced",
      "1/4 cup green olives, pitted and sliced",
      "2 tablespoons harissa paste (adjust to taste)",
      "1/4 cup tomato sauce or crushed tomatoes",
      "1/2 teaspoon dried basil",
      "Salt, to taste",
      "Freshly ground black pepper, to taste",
      "Grated Parmesan or vegan cheese alternative for serving (optional)",
    ],
  },
  {
    id: "hcbjwhufjcbd",
    title: "Roast lamb with marsala gravy",
    duration: formatDuration(
      { hours: 1, minutes: 20 },
      { locale: customLocale }
    ),
    numberOfIngredients: 8,
    dateAdded: sub(new Date(), { days: 45 }),
    image:
      "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/roast-lamb-marsala.jpg",
    user: {
      img: "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/user-Norma_j3an.jpg",
      username: "@SallyTheButcher",
    },
    rating: 4.9,
    ingredients: [
      "1 leg of lamb (around 2-2.5 kg)",
      "3 cloves garlic, sliced",
      "2 sprigs of fresh rosemary",
      "Salt and freshly ground black pepper, to taste",
      "2 tablespoons olive oil",
      "1/2 cup marsala wine",
      "1 cup beef or lamb stock",
      "2 tablespoons unsalted butter or ghee",
    ],
  },
  {
    id: "1b2b3c4d5e6e",
    duration: formatDuration({ minutes: 30 }, { locale: customLocale }),
    dateAdded: sub(new Date(), { days: 16 }),
    title: "Spaghetti Aglio e Olio",
    user: {
      img: "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/user-Isaac3000.jpg",
      username: "@Isaac3000",
    },
    image:
      "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/spaghetti-aglio.jpg",
    numberOfIngredients: 5,
    rating: 4.4,
    isMade: false,
    ingredients: [
      "200g spaghetti",
      "3 cloves garlic, thinly sliced",
      "1/4 teaspoon red pepper flakes (adjust to taste)",
      "1/4 cup olive oil",
      "Parsley, chopped for garnish",
    ],
  },
  {
    id: "7h8h9i0j1k2k",
    duration: formatDuration({ minutes: 25 }, { locale: customLocale }),
    dateAdded: sub(new Date(), { days: 22 }),
    title: "Grilled Lemon Herb Chicken Salad",
    user: {
      img: "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/user-Norma_j3an.jpg",
      username: "@Norma_j3an",
    },
    image:
      "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/chicken-salad.jpg",
    numberOfIngredients: 7,
    rating: 4.1,
    isMade: true,
    ingredients: [
      "2 boneless, skinless chicken breasts",
      "1 lemon, zested and juiced",
      "2 cloves garlic, minced",
      "1 teaspoon mixed dried herbs (e.g., rosemary, thyme, oregano)",
      "2 tablespoons olive oil",
      "4 cups mixed salad greens (e.g., lettuce, arugula, spinach)",
      "1/4 cup cherry tomatoes, halved",
    ],
  },
  {
    id: "3n4n5o6p7q8q",
    duration: formatDuration({ minutes: 45 }, { locale: customLocale }),
    dateAdded: sub(new Date(), { days: 34 }),
    title: "Vegetable Stir Fry with Tofu",
    user: {
      img: "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/user-Funtime-chef.jpg",
      username: "@Funtime-chef",
    },
    image:
      "https://res.cloudinary.com/dhrbbwjkf/image/upload/foodx/tofu-stir-fry.jpg",
    numberOfIngredients: 8,
    rating: 3.8,
    isMade: false,
    ingredients: [
      "200g firm tofu, cubed",
      "2 tablespoons soy sauce",
      "1 tablespoon hoisin sauce",
      "1 clove garlic, minced",
      "1 teaspoon ginger, minced",
      "1 cup broccoli florets",
      "1 red bell pepper, sliced",
      "1 carrot, julienned",
    ],
  },
];

export default dummyRecipes;
