import { createContext, useContext, useReducer } from "react";
import dummyIngredients from "../dummyData/ingredients";

export const ACTIONS = {
  GET_SHOP_LIST: "GET_SHOP_LIST",
  GET_SHELF_LIST: "GET_SHELF_LIST",
  MOVE_TO_SHELF: "MOVE_TO_SHELF",
  MOVE_TO_SHOP: "MOVE_TO_SHOP",
};

const initialState = {
  shopList: dummyIngredients,
  shelfList: [],
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.GET_SHOP_LIST:
      return { ...state, shopList: state.shopList };
    case ACTIONS.GET_SHELF_LIST:
      return { ...state, shelfList: state.shelfList };
    case ACTIONS.MOVE_TO_SHELF:
      return {
        ...state,
        shopList: state.shopList.filter(
          (item) => !action.payload.includes(item)
        ),
        shelfList: [...state.shelfList, ...action.payload],
      };
    case ACTIONS.MOVE_TO_SHOP:
      return {
        ...state,
        shelfList: state.shelfList.filter(
          (item) => !action.payload.includes(item)
        ),
        shopList: [...state.shopList, ...action.payload],
      };
    default:
      return state;
  }
}

const IngredientsContext = createContext();

export function IngredientsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <IngredientsContext.Provider value={{ state, dispatch }}>
      {children}
    </IngredientsContext.Provider>
  );
}

export const useIngredients = () => useContext(IngredientsContext);
