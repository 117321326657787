import { Button, Square, YStack } from "tamagui";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { UIText } from "../../components";
import { ImageBackground } from "expo-image";
import welcomeImage from "../../../assets/welcome-background.png";

export default function WelcomeScreen({ navigation }) {
  const onNavigateToSignUp = () => {
    navigation.navigate("SignUp");
  };

  const onNavigateToLogin = () => {
    navigation.navigate("Login");
  };

  return (
    <ImageBackground source={welcomeImage} contentFit="cover">
      <FullScreenLayout py={64} backgroundColor="transparent">
        <YStack
          alignItems="center"
          justifyContent="center"
          flex={1}
          width="100%"
          gap={40}
        >
          <YStack alignItems="center">
            <UIText fontSize={21} fontWeight="600" textAlign="center">
              Discover Delicious:
            </UIText>
            <UIText fontSize={21} fontWeight="600" textAlign="center">
              Your Personalized Meal Generator
            </UIText>
          </YStack>
          <Square height={220} width={220} />
          <UIText fontSize={40} fontWeight="600">
            FoodX
          </UIText>
        </YStack>
        <YStack width="100%" padding={16} gap={16}>
          <Button
            alignItems="center"
            backgroundColor="#000"
            borderRadius={4}
            height={44}
            justifyContent="center"
            width="100%"
            pressStyle={{ opacity: 0.5 }}
            focusStyle={{ borderWidth: 0, outlineWidth: 1 }}
            onPress={onNavigateToSignUp}
          >
            <UIText color="#FFF" fontWeight={600}>
              Sign Up
            </UIText>
          </Button>
          <Button
            alignItems="center"
            backgroundColor="#FFF"
            borderColor="#000"
            borderRadius={4}
            borderWidth={1}
            height={44}
            justifyContent="center"
            width="100%"
            pressStyle={{ opacity: 0.5 }}
            focusStyle={{ borderWidth: 0, outlineWidth: 1 }}
            onPress={onNavigateToLogin}
          >
            <UIText color="#000" fontWeight={600}>
              Login
            </UIText>
          </Button>
        </YStack>
      </FullScreenLayout>
    </ImageBackground>
  );
}
