import { FlatList } from "react-native";
import { UIText } from "../../components";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import SharedMealsCard from "../../sections/sharedMeals/SharedMealsCard";
import { Input, ScrollView, XStack, YStack } from "tamagui";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useEffect, useState } from "react";
import {
  useGetPromptsQuery,
  useGetRecommendationsQuery,
  useLazyGetRecommendationsQuery,
} from "../../redux/api/api-slice";
const showChef = false;
export default function RecipeRecommendationsScreen({ route }) {
  const { recipes } = route.params || {};
  
  return (
    <ScrollView>
      <FullScreenLayout paddingTop={16}>
        <YStack width="100%" gap={18} px={16} backgroundColor="white">
          <UIText fontSize={21} fontWeight={700}>
            Recommendations
          </UIText>

          <FlatList
            data={recipes}
            renderItem={({ item }) => {
              return (
                <SharedMealsCard
                  recipe={{ ...item, image: item.images?.split(",")[0] }}
                  showChef={showChef}
                  cardStyle={{
                    width: "50%",
                  }}
                />
              );
            }}
            keyExtractor={(item) => item.id}
            numColumns={2}
          />
        </YStack>
      </FullScreenLayout>
    </ScrollView>
  );
}
