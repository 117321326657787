import { NavigationContainer } from "@react-navigation/native";
import Router from "./src/navigation/Router";
import { Provider } from "react-redux";
import { store } from "./src/redux/store";
import { TamaguiProvider } from "tamagui";
import config from "./tamagui.config";
import { useColorScheme } from "react-native";
import {
  DarkTheme,
  DefaultTheme,
  ThemeProvider,
} from "@react-navigation/native";
import {
  useFonts,
  Lato_100Thin,
  Lato_100Thin_Italic,
  Lato_300Light,
  Lato_300Light_Italic,
  Lato_400Regular,
  Lato_400Regular_Italic,
  Lato_700Bold,
  Lato_700Bold_Italic,
  Lato_900Black,
  Lato_900Black_Italic,
} from "@expo-google-fonts/lato";
import useLoadFonts from "./src/hooks/useLoadFonts";
import Toast from "react-native-toast-message";

export default function App() {
  const colorScheme = useColorScheme();
  const [loaded] = useFonts({
    Inter: require("@tamagui/font-inter/otf/Inter-Medium.otf"),
    InterBold: require("@tamagui/font-inter/otf/Inter-Bold.otf"),
    Lato_100Thin,
    Lato_100Thin_Italic,
    Lato_200Thin: Lato_100Thin,
    Lato_200Thin_Italic: Lato_100Thin_Italic,
    Lato_300Light,
    Lato_300Light_Italic,
    Lato_400Regular,
    Lato_400Regular_Italic,
    Lato_500Regular: Lato_400Regular,
    Lato_500Regular_Italic: Lato_400Regular_Italic,
    Lato_600Regular: Lato_400Regular,
    Lato_600Regular_Italic: Lato_400Regular_Italic,
    Lato_700Bold,
    Lato_700Bold_Italic,
    Lato_800Regular: Lato_700Bold,
    Lato_800Regular_Italic: Lato_700Bold_Italic,
    Lato_900Black,
    Lato_900Black_Italic,
  });
  const { fontsLoaded } = useLoadFonts();
  if (!loaded || !fontsLoaded) {
    return null;
  }
  return (
    <Provider store={store}>
      <TamaguiProvider config={config}>
        <ThemeProvider
          value={colorScheme === "dark" ? DarkTheme : DefaultTheme}
        >
          <NavigationContainer>
            <Router />
          </NavigationContainer>
          <Toast />
        </ThemeProvider>
      </TamaguiProvider>
    </Provider>
  );
}
