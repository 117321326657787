import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import FeedScreen from "../screens/auth/Feed";
import CookBookScreen from "../screens/auth/CookBook";
import GenerateScreen from "../screens/auth/Generate";
import IngredientsScreen from "../screens/auth/Ingredients";
import ProfileScreen from "../screens/auth/Profile";
import WelcomeScreen from "../screens/public/Welcome";
import SignUpScreen from "../screens/public/SignUp";
import LoginScreen from "../screens/public/Login";
// TODO: use icons from Figma
import Ionicons from "@expo/vector-icons/Ionicons";
import AntDesign from "@expo/vector-icons/AntDesign";
import FeatherIcons from "@expo/vector-icons/Feather";
import SharedMeals from "../screens/auth/SharedMeals";
import RecommendationsScreen from "../screens/auth/Recommendations";
import IngredientRecipesScreen from "../screens/auth/IngredientRecipes";
import RecipeScreen from "../screens/auth/Recipe";
import ShoppingCartScreen from "../screens/auth/ShoppingCart";
import { Stack } from "tamagui";
import { IngredientsProvider } from "../context/IngredientsContext";
import SignUpCreateCredentialsScreen from "../screens/public/SignUpCreateCredentials";
import OnboardingEntryScreen from "../screens/public/OnboardingEntry";
import OnboardingScreen from "../screens/public/Onboarding";
import useIsAuthenticated from "../hooks/useIsAuthenticated";
import useLoadDataFromStore from "../hooks/useLoadDataFromStore";
import useAppRedirect from "../hooks/useAppRedirect";
import MealDetailsScreen from "../screens/auth/MealDetails";
import useGetUser from "../hooks/useGetUser";
import PreviousPromptsScreen from "../screens/auth/PreviousPrompts";
import AddShelfScreen from "../screens/auth/AddShelf";
import AddToShoppingCartScreen from "../screens/auth/AddToShoppingCart";

const PublicStack = createNativeStackNavigator();
const AuthenticatedStack = createNativeStackNavigator();
const MainTabNav = createBottomTabNavigator();
const FeedStack = createNativeStackNavigator();
const IngredientsStack = createNativeStackNavigator();

function FeedRoutes() {
  return (
    <FeedStack.Navigator>
      <FeedStack.Screen
        name="Feed"
        component={FeedScreen}
        options={{ headerShown: false }}
      />
      <FeedStack.Screen
        name="SharedMeals"
        component={SharedMeals}
        options={{
          headerTitle: "Shared Meals",
          headerBackTitle: "",
        }} // TODO: add filter component to header (likely will need to create a custom header)
      />
    </FeedStack.Navigator>
  );
}

function IngredientsRoutes() {
  return (
    <IngredientsStack.Navigator>
      <IngredientsStack.Screen
        name="Ingredients"
        component={IngredientsScreen}
        options={{ headerShown: false }}
      />
      <IngredientsStack.Screen
        name="IngredientRecipes"
        component={IngredientRecipesScreen}
        options={{
          headerTitle: "Ingredient",
          headerBackTitle: "",
          headerTitleAlign: "center",
          headerTitleStyle: {
            fontSize: 14,
          },
        }}
      />
      <IngredientsStack.Screen
        name="AddShelf"
        component={AddShelfScreen}
        options={{
          headerTitle: "Add to shelf",
          headerBackTitle: "",
          headerTitleAlign: "center",
          headerTitleStyle: {
            fontSize: 14,
          },
        }}
      />
      <IngredientsStack.Screen
        name="AddToShoppingCart"
        component={AddToShoppingCartScreen}
        options={{
          headerTitle: "Add to shopping cart",
          headerBackTitle: "",
          headerTitleAlign: "center",
          headerTitleStyle: {
            fontSize: 14,
          },
        }}
      />
    </IngredientsStack.Navigator>
  );
}

function MainTabs() {
  useAppRedirect(); // are there scenarios which aren't covered by the current placement of the hook?
  return (
    <MainTabNav.Navigator
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: "#FFFFFF",
        tabBarInactiveTintColor: "#000000",
        tabBarActiveBackgroundColor: "#303030",
        tabBarItemStyle: { padding: 3 },
      }}
    >
      <MainTabNav.Screen
        name="FeedTab"
        component={FeedRoutes}
        options={{
          tabBarLabel: "Feed",
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="home-outline" color={color} size={size} />
          ),
        }}
      />
      <MainTabNav.Screen
        name="CookBook"
        component={CookBookScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <AntDesign name="book" color={color} size={size} />
          ),
        }}
      />
      <MainTabNav.Screen
        name="GenerateTab"
        component={RecommendationsScreen}
        listeners={({ navigation }) => ({
          tabPress: (e) => {
            e.preventDefault();
            navigation.navigate("Generate");
          },
        })}
        options={{
          tabBarLabel: "Generate",
          tabBarLabelStyle: { color: "#000000" },
          tabBarIcon: ({ color, size, focused }) => {
            return (
              <FeatherIcons name="plus-square" color={"#000000"} size={size} />
            );
          },
          tabBarActiveBackgroundColor: "#DEDEDE",
        }}
      />
      <MainTabNav.Screen
        name="IngredientsTab"
        component={IngredientsRoutes}
        options={{
          tabBarLabel: "Ingredients",
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="list-outline" color={color} size={size} />
          ),
        }}
      />
      <MainTabNav.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Ionicons name="person-circle-outline" color={color} size={size} />
          ),
        }}
      />
    </MainTabNav.Navigator>
  );
}

function PublicRoutes() {
  return (
    <PublicStack.Navigator>
      <PublicStack.Screen
        name="Welcome"
        component={WelcomeScreen}
        options={{ headerShown: false }}
      />
      <PublicStack.Screen
        name="SignUp"
        component={SignUpScreen}
        options={{
          headerBackTitle: "",
          headerTitle: "Sign Up",
          headerTitleAlign: "center",
          headerTitleStyle: {
            fontSize: 14,
          },
        }}
      />
      <PublicStack.Screen
        name="SignUpCreateCredentials"
        component={SignUpCreateCredentialsScreen}
        options={{
          headerBackTitle: "",
          headerTitle: "Sign Up",
          headerTitleAlign: "center",
          headerTitleStyle: {
            fontSize: 14,
          },
        }}
      />
      <PublicStack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          headerBackTitle: "",
          headerTitle: "Login",
          headerTitleAlign: "center",
          headerTitleStyle: {
            fontSize: 14,
          },
        }}
      />
    </PublicStack.Navigator>
  );
}

function AuthenticatedRoutes() {
  return (
    <IngredientsProvider>
      {/* In the absence of better placement, we'll just wrap the whole auth stack  */}
      <AuthenticatedStack.Navigator>
        <AuthenticatedStack.Screen
          name="Home"
          component={MainTabs}
          options={{ headerShown: false }}
        />
        <AuthenticatedStack.Screen
          name="Generate"
          component={GenerateScreen}
          options={{ headerShown: false }}
        />
        <AuthenticatedStack.Screen
          name="Recipe"
          component={RecipeScreen}
          options={{
            headerShown: false,
          }}
        />
        <AuthenticatedStack.Screen
          name="MealDetails"
          component={MealDetailsScreen}
          options={{
            headerShown: false,
          }}
        />
        <AuthenticatedStack.Screen
          name="PromptHistory"
          component={PreviousPromptsScreen}
          options={{
            headerTitle: "",
            headerBackTitle: "",
          }}
        />
        <AuthenticatedStack.Screen
          name="ShoppingCart"
          component={ShoppingCartScreen}
          options={{
            headerBackTitle: "",
            headerTitle: "Cart",
            headerTitleAlign: "center",
            headerTitleStyle: {
              fontSize: 14,
            },
            headerRight: () => (
              <Stack padding={8} onPress={() => console.log("TODO...")}>
                <Ionicons name="ellipsis-horizontal" size={20} />
              </Stack>
            ),
          }}
        />
        <AuthenticatedStack.Screen
          name="OnboardingEntry"
          component={OnboardingEntryScreen}
          options={{ headerShown: false }}
        />
        <AuthenticatedStack.Screen
          name="Onboarding"
          component={OnboardingScreen}
          options={{ headerShown: false }}
        />
      </AuthenticatedStack.Navigator>
    </IngredientsProvider>
  );
}

export default function Router() {
  const {
    /* TODO: make use of loading, error, and done state */
  } = useLoadDataFromStore();
  useGetUser();
  const isAuthenticated = useIsAuthenticated(); // TODO: get value from state management
  if (isAuthenticated) {
    return <AuthenticatedRoutes />;
  }

  return <PublicRoutes />;
}
