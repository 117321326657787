import { XStack, YStack } from "tamagui";
import { UIText } from "../../components";
import { TouchableOpacity } from "react-native";

const preferredCookingMethods = [
  { value: "Stove top", promptValue: "a stove top" },
  { value: "Oven", promptValue: "an oven" },
  { value: "Airfryer", promptValue: "an airfryer" },
  { value: "Grill", promptValue: "a grill" },
  {
    value: "Any",
    promptValue: "any cooking method(stove top, airfryer, ove, grill, etc...)",
  },
];

function PreferredCookingMethodOption({ cookingMethod, isSelected }) {
  return (
    <XStack
      alignItems="center"
      justifyContent="center"
      gap={16}
      width="100%"
      borderWidth={1}
      borderRadius={4}
      px={16}
      py={12}
      backgroundColor={isSelected ? "#EFEFEF" : undefined} // TODO: what color should selection be?
    >
      <UIText>{cookingMethod.value}</UIText>
    </XStack>
  );
}

export default function PreferredCookingMethod({
  promptData,
  setGeneratePrompt,
}) {
  const selectedPreferredCookingMethod = promptData.preferredCookingMethod;

  const onSelect = (newSelectedPreferredCookingMethod) => {
    setGeneratePrompt((prev) => ({
      ...prev,
      preferredCookingMethod: newSelectedPreferredCookingMethod,
    }));
  };

  return (
    <YStack alignItems="center" gap={36}>
      <UIText fontSize={21}>What’s your preferred cooking method?</UIText>
      <YStack gap={12} width="100%">
        {preferredCookingMethods.map((preferredCookingMethod) => {
          const isSelected =
            preferredCookingMethod.promptValue ===
            selectedPreferredCookingMethod;
          return (
            <TouchableOpacity
              key={preferredCookingMethod}
              onPress={() => onSelect(preferredCookingMethod.promptValue)}
            >
              <PreferredCookingMethodOption
                cookingMethod={preferredCookingMethod}
                isSelected={isSelected}
              />
            </TouchableOpacity>
          );
        })}
      </YStack>
    </YStack>
  );
}
