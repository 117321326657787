import FullScreenLayout from "../../layouts/FullScreenLayout";
import { Button, Text, XStack, YStack } from "tamagui";
import Ionicons from "@expo/vector-icons/Ionicons";

/**
 * TODO: determine if this should be a public route
 */
export default function OnboardingEntryScreen({ navigation }) {
  const onNavigateToOnboarding = () => {
    navigation.navigate("Onboarding");
  };

  return (
    <FullScreenLayout
      alignItems="flex-start"
      gap={24}
      px={16}
      paddingTop={66}
      paddingBottom={36}
      width="100%"
    >
      <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontSize={17} fontWeight="500">
        Welcome to
      </Text>
      <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontSize={40} fontWeight="600">
        FoodX
      </Text>
      <YStack width="100%" gap={16}>
        <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="#4D4C4C">
          Before we start generating your next meal we’d like to know a few
          things before we jump in!
        </Text>
        <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="#4D4C4C">
          To tailor our recommendations for you please let us know about your
          specific:
        </Text>
      </YStack>
      <YStack flex={1} gap={24}>
        <XStack alignItems="center" gap={24}>
          <Ionicons name="clipboard-outline" size={24} />
          <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontStyle="italic" fontWeight="500">
            Dietary requirement/preferences
          </Text>
        </XStack>
        <XStack alignItems="center" gap={24}>
          <Ionicons name="speedometer-outline" size={24} />
          <Text fontFamily="Inter, Helvetica, Arial, sans-serif" fontStyle="italic" fontWeight="500">
            Cooking experience/level
          </Text>
        </XStack>
      </YStack>
      <YStack alignItems="center" gap={16} width="100%">
        <Button
          alignItems="center"
          backgroundColor="#000"
          borderRadius={4}
          height={44}
          justifyContent="center"
          width="100%"
          pressStyle={{ opacity: 0.5 }}
          focusStyle={{ borderWidth: 0, outlineWidth: 1 }}
          onPress={onNavigateToOnboarding}
        >
          <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="#FFF" fontWeight="600">
            Get Started
          </Text>
        </Button>
        <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="#7E7E7E" fontSize={12} textAlign="center">
          You can change these options later in your Profile
        </Text>
      </YStack>
    </FullScreenLayout>
  );
}
