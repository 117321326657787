import { Input, XStack, YStack, Spinner } from "tamagui";
import { UIText } from "../../components/UIText";
import { FlatList, ScrollView } from "react-native";
import SharedMealsCard from "../../sections/sharedMeals/SharedMealsCard";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useGetRecipesQuery } from "../../redux/api/api-slice";
import { useEffect, useState } from "react";
import { debounce } from "../../utility/generalFunctions";

const showChef = false;

export default function CookBookScreen() {
  const {
    isLoading,
    isError, // TODO: toast message
    isSuccess,
    data: recipes = [],
  } = useGetRecipesQuery();

  const [filterString, setFilterString] = useState("");
  const [filteredRecipes, setFilteredRecipes] = useState(recipes);

  useEffect(() => {
    setFilteredRecipes(
      filterString
        ? recipes.filter((recipe) =>
            `${recipe.name}, ${recipe.ingredients}, ${recipe.nutritional_summary}`
              .toLowerCase()
              .includes(filterString.toLowerCase())
          )
        : recipes
    );
  }, [filterString, recipes]);

  if (isLoading) {
    return (
      <FullScreenLayout justifyContent="center">
        <Spinner color="black" size="large" />
      </FullScreenLayout>
    );
  }

  return (
    <ScrollView>
      <FullScreenLayout paddingTop={16}>
        <YStack width="100%" gap={18} px={16} backgroundColor="white">
          <UIText fontSize={21} fontWeight={700}>
            Cookbook
          </UIText>
          <UIText fontSize={14} fontWeight={400}>
            All your bookmarked recipes are saved here
          </UIText>
          <XStack width="100%" alignItems="center" space="$2">
            <Input
              style={{
                height: 40,
                width: "85%",
                borderRadius: 6,
                border: "#B0B0B0",
              }}
              onChangeText={debounce((text) => setFilterString(text), 800)}
            />{" "}
            <Ionicons
              name="search"
              size={24}
              color="black"
              style={{ borderColor: "#B0B0B0", borderRadius: 6 }}
            />
          </XStack>
          <XStack
            width="100%"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            {filterString ? (
              <UIText fontSize={18} fontWeight={500}>
                {filteredRecipes.length} found out of {recipes?.length ?? 0}{" "}
                saved recipes
              </UIText>
            ) : (
              <UIText fontSize={18} fontWeight={500}>
                {recipes?.length ?? 0} recipes saved
              </UIText>
            )}
          </XStack>
          <FlatList
            data={filteredRecipes}
            renderItem={({ item }) => {
              return (
                <SharedMealsCard
                  recipe={{ ...item, image: item.images?.split(",")[0] }}
                  showChef={showChef}
                  cardStyle={{
                    width: "50%",
                  }}
                />
              );
            }}
            keyExtractor={(item) => item.id}
            numColumns={2}
          />
        </YStack>
      </FullScreenLayout>
    </ScrollView>
  );
}
