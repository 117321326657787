import { Circle, XStack, YStack, TextArea } from "tamagui";
import { UIText } from "../../components";
import Ionicons from "@expo/vector-icons/Ionicons";

export default function AdditonalComments({ promptData, setGeneratePrompt }) {
  const providedAdditonalComments = promptData.additionalComments;

  const onUpdate = (newAdditonalComments) => {
    setGeneratePrompt((prev) => ({
      ...prev,
      additionalComments: newAdditonalComments,
    }));
  };

  return (
    <YStack gap={12} height="100%" flexShrink={1} flexGrow={1}>
      <UIText fontSize={21}>
        Anything else you’d like to suggest or leave out?
      </UIText>
      <YStack gap={6} paddingBottom={18}>
        <XStack alignItems="flex-end" gap={8}>
          <Ionicons name="mic-outline" color="#B6B6B6" size={16} />
          <UIText color="#4D4C4C" fontStyle="italic">
            Input via voice
          </UIText>
        </XStack>
      </YStack>
      <YStack gap={8}>
        <UIText fontSize={11} fontWeightt={700}>
          COMMENT
        </UIText>
        <TextArea
          backgroundColor="#F5F5F5"
          borderWidth={0}
          borderRadius={4}
          minHeight={180}
          padding={12}
          keyboardType="default"
          value={providedAdditonalComments}
          onChangeText={onUpdate}
          placeholder={"e.g. I’d like sugar to be replaced with honey"}
          placeholderTextColor="#999999"
          focusStyle={{
            outlineColor: "black",
            outlineWidth: 0,
          }}
        />
      </YStack>
      <XStack
        justifyContent="center"
        alignItems="center"
        borderRadius={100}
        py={24}
        pressStyle={{ opacity: 0.5 }}
        // TODO: allow for voice input
        onPress={() => console.log("TODO...")}
      >
        <Circle
          borderColor="black"
          borderWidth={1.5}
          size={64}
          justifyContent="center"
          alignItems="center"
        >
          <Ionicons name="mic-outline" size={36} color="black" />
        </Circle>
      </XStack>
    </YStack>
  );
}
