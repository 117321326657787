import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
// import { removeTokens, setTokens } from "../features/auth-slice";
import reduxConstants from "../reduxConstants";
import { RootState } from "../store";
import { removeTokens, setTokens } from "../features/auth-slice";
import { unsetUser } from "../features/user-slice";
import { ApiResponse } from "../../utility/generalFunctions";

/**
 * TODO: set this file up to correspond to server setup (auth token management, base URL, endpoints, etc...)
 */

const API_BASE_URL = "https://foodx-backend.siglonet.com/api"; // TODO: use env variables

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const userTokens = (getState() as RootState).auth;
    if (typeof userTokens.token === "string") {
      headers.set("Authorization", `Bearer ${userTokens.token}`);
    }
    headers.set("Accept", "application/json");
    return headers;
  },
});

// TODO: investigate if this needs to be its own base query, or if above can use dynamic headers
// TODO: implement refresh tokens on backend
const refreshQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const userTokens = (getState() as RootState).auth;
    if (typeof userTokens.refresh_token === "string") {
      headers.set("Authorization", `Bearer ${userTokens.refresh_token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const userTokens = (api.getState() as RootState).auth;

    // try to get a new token
    const refreshResult = await refreshQuery(
      {
        url: "/refresh",
        method: "POST",
      },
      api,
      extraOptions
    );
    if (refreshResult.error) {
      console.error(refreshResult.error);
      api.dispatch(removeTokens());
      api.dispatch(unsetUser());
    } else if (refreshResult?.data) {
      // store the new token
      api.dispatch(
        setTokens({
          ...userTokens,
          token: refreshResult.data.token,
        })
      );
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: reduxConstants.API.NAME,
  baseQuery: baseQueryWithReauth,
  // map out the expected req/res of endpoints from the api
  endpoints: (builder) => {
    return {
      login: builder.mutation({
        query: (body) => ({
          url: "auth/login",
          method: "POST",
          body,
        }),
      }),
      logout: builder.mutation<unknown, void>({
        query: () => ({
          url: "auth/logout",
          method: "POST",
        }),
      }),
      signUp: builder.mutation({
        query: (body) => {
          return {
            url: "user",
            method: "POST",
            body,
          };
        },
      }),
      onboard: builder.mutation({
        query: (body) => {
          return {
            url: "user/onboard",
            method: "PATCH",
            body,
          };
        },
      }),
      getUser: builder.query<ApiResponse, void>({
        query: () => "user",
      }),
      getDietaryRequiremnets: builder.query<ApiResponse, void>({
        query: () => "dietary-requirements",
      }),
      submitPrompt: builder.mutation({
        query: (body) => {
          return {
            url: "prompt",
            method: "POST",
            body,
          };
        },
        invalidatesTags: [{ type: "Prompts", id: "LIST" }],
      }),
      getShelf: builder.query({
        query: () => "shelf",
      }),
      getPrompts: builder.query<any, void>({
        query: () => "prompt",
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: "Prompts" as const, id })),
                { type: "Prompts", id: "LIST" },
              ]
            : [{ type: "Prompts", id: "LIST" }],
      }),
      getRecipes: builder.query<any, void>({
        query: () => "recipe",
        providesTags: (result) => {
          return result
            ? [
                ...result.map(({ id }) => ({ type: "Recipes" as const, id })),
                { type: "Recipes", id: "LIST" },
              ]
            : [{ type: "Recipes", id: "LIST" }];
        },
      }),
      getRecipe: builder.query<any, { recipeId: string | number }>({
        query: ({ recipeId }) => `recipe/${recipeId}`,
        providesTags: (result, error, recipeId) => {
          console.log("getRecipe", { result, error, recipeId });
          return [{ type: "Recipes", id: recipeId }];
        },
      }),
      generateRecipeInstructions: builder.mutation<
        any,
        { id: string | number }
      >({
        query: ({ id }) => {
          return {
            url: "recipe/instructions",
            method: "PATCH",
            body: { recipeId: id },
          };
        },
        invalidatesTags: (result, error, data) => {
          const { id } = data;
          return [{ type: "Recipes", id }];
        },
      }),
      getRecipesSharedWithMe: builder.query<any, { page: number }>({
        query: ({ page = 1 }) =>
          `recipe/shared?${new URLSearchParams({
            page: page.toString(),
          }).toString()}`,
        providesTags: (result) =>
          result
            ? [
                ...result.data.map(({ id }) => ({
                  type: "Recipes" as const,
                  id,
                })),
                { type: "Recipes", id: "SHARED_LIST" },
              ]
            : [{ type: "Recipes", id: "SHARED_LIST" }],
      }),
      saveRecipe: builder.mutation<ApiResponse, { requestid: string }>({
        query: (body) => {
          return {
            url: "recipe/save",
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: [{ type: "Recipes", id: "LIST" }],
      }),
      submitShelf: builder.mutation({
        query: (body) => {
          return {
            url: "shelf",
            method: "POST",
            body,
          };
        },
        invalidatesTags: [{ type: "Ingredients", id: "LIST" }],
      }),
      getRecommendations: builder.query({
        query: () => "recommendations",
      }),
      // TODO: remove examples
      getStuff: builder.query({
        query: () => "stuff",
      }),
      getSpecificStuff: builder.query({
        query: ({ pointySides = 0, colour = "" }) => {
          return `stuff?${new URLSearchParams({
            pointySides: pointySides.toString(),
            colour,
          }).toString()}`;
        },
      }),
    };
  },
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useSignUpMutation,
  useOnboardMutation,
  useSubmitPromptMutation,
  useLazyGetShelfQuery,
  useGetShelfQuery,
  useGetPromptsQuery,
  useGetRecipesQuery,
  useGetRecipeQuery,
  useGetRecipesSharedWithMeQuery,
  useGenerateRecipeInstructionsMutation,
  useSaveRecipeMutation,
  useLazyGetRecommendationsQuery,
  useGetStuffQuery,
  useGetSpecificStuffQuery,
  useLazyGetUserQuery,
  useSubmitShelfMutation,
  useGetDietaryRequiremnetsQuery,
} = apiSlice;
