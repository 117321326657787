import { ScrollView } from "react-native";
import FullScreenLayout from "../../layouts/FullScreenLayout";
import { UIText } from "../../components";
import PreviousPrompts from "../../sections/previousPrompts/PreviousPrompts";
import SharedMeals from "../../sections/sharedMeals/SharedMeals";

export default function FeedScreen() {
  return (
    <ScrollView>
      <FullScreenLayout paddingTop={32} px={24} gap={24}>
        <UIText fontWeight={600} marginBottom={12}>
          FoodX
        </UIText>
        {/* TODO:
         * Handle "nested VirtualizedLists" error as PreviousPrompts below is nested within the Scrollview here
         * Consider using a SectionList
         */}
        <PreviousPrompts />
        <SharedMeals />
      </FullScreenLayout>
    </ScrollView>
  );
}
