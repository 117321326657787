import * as React from "react";
import { useAppSelector } from "../redux/hooks";
import { useNavigation, useRoute } from "@react-navigation/native";
import { AppUser } from "../types";
import useIsAuthenticated from "./useIsAuthenticated";

// redirect to certain screens based on certain values in the app state
export default function useAppRedirect() {
  const { reset } = useNavigation();
  const route = useRoute();
  const { data: user }: { data: AppUser | null } = useAppSelector((state) => {
    return state.user;
  });
  const isAuthenticated = useIsAuthenticated();

  const { name: currentRouteName } = route;

  React.useEffect(() => {
    // navigate to Onboarding screens if user.mustDoOnboarding
    if (
      isAuthenticated && // in practice this is probably always true here
      user?.mustDoOnboarding &&
      !["OnboardingEntry", "Onboarding"].includes(currentRouteName)
    ) {
      reset({
        index: 0,
        routes: [{ name: "OnboardingEntry" }],
      });
    }
  }, [currentRouteName, user?.mustDoOnboarding, isAuthenticated]);
}
