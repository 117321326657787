import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useLazyGetUserQuery } from "../redux/api/api-slice";
import { isErrorResponse } from "../utility/generalFunctions";
import { setUser } from "../redux/features/user-slice";
import { AppUser, UserSchema } from "../types";

export default function useGetUser() {
  const userTokens = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);
  const [getUser] = useLazyGetUserQuery();
  const dispatch = useAppDispatch();
  const fetchUser = async () => {
    try {
      const response = await getUser();
      if (isErrorResponse(response)) {
        throw response.error;
      }
      const parsedUser = UserSchema.parse(response.data) as AppUser;
      dispatch(setUser(parsedUser));
    } catch (error) {
      // TODO: show message in Toast
      console.error(error);
    }
  };
  useEffect(() => {
    if (!userTokens.token || user.data) {
      return;
    }
    fetchUser();
  }, [userTokens, user]);
}
