import { Image, XStack, YStack } from "tamagui";
import { UIText } from "../../components";
import { TouchableOpacity } from "react-native";
import level1Img from "../../../assets/images/signal_1-of-3.png";
import level2Img from "../../../assets/images/signal_2-of-3.png";
import level3Img from "../../../assets/images/signal_3-of-3.png";
// import ThreeBarsIcon from '../../../assets/vectors/ThreeBars'
import { IOnboardingData } from "../../types";

const iconSize = 24;

interface IProps {
  userOnboardingData: IOnboardingData;
  setUserOnboardingData: React.Dispatch<React.SetStateAction<IOnboardingData>>;
}

interface IOptionProps {
  name: string;
  icon: React.JSX.Element;
  isSelected?: boolean;
}

const cookingLevels = [
  {
    name: "Beginner/Casual Quick Meals",
    icon: (
      <Image
        source={{ uri: level1Img }}
        resizeMode="cover"
        width={iconSize}
        height={iconSize}
      />
    ),
    // icon: <ThreeBarsIcon barLevel={1} height={iconSize} width={iconSize} />,
  },
  {
    name: "Intermediate/Regular Cook",
    icon: (
      <Image
        source={{ uri: level2Img }}
        resizeMode="cover"
        width={iconSize}
        height={iconSize}
      />
    ),
    // icon: <ThreeBarsIcon barLevel={2} height={iconSize} width={iconSize} />,
  },
  {
    name: "Expert/Serious Chef",
    icon: (
      <Image
        source={{ uri: level3Img }}
        resizeMode="cover"
        width={iconSize}
        height={iconSize}
      />
    ),
    // icon: <ThreeBarsIcon barLevel={3} height={iconSize} width={iconSize} />,
  },
];

function CookingExperienceLevelOption({
  name,
  icon,
  isSelected,
}: IOptionProps) {
  return (
    <XStack
      alignItems="center"
      gap={16}
      width="100%"
      borderWidth={1}
      borderRadius={4}
      px={16}
      py={12}
      backgroundColor={isSelected ? "#EFEFEF" : undefined} // TODO: what color should selection be?
    >
      {icon}
      <UIText>{name}</UIText>
    </XStack>
  );
}

export default function CookingExperienceLevel({
  userOnboardingData,
  setUserOnboardingData,
}: IProps) {
  const selectedCookingExperienceLevel = userOnboardingData.experience;

  const onSelect = (newSelectedCookingExperienceLevel: string) => {
    setUserOnboardingData((prev) => ({
      ...prev,
      experience: newSelectedCookingExperienceLevel,
    }));
  };

  return (
    <YStack alignItems="center" gap={36}>
      <UIText fontSize={21}>What type of meal are you looking to make?</UIText>
      <YStack gap={12} width="100%">
        {cookingLevels.map((cookingLevel) => {
          const isSelected =
            cookingLevel.name === selectedCookingExperienceLevel;
          return (
            <TouchableOpacity
              key={cookingLevel.name}
              onPress={() => onSelect(cookingLevel.name)}
            >
              <CookingExperienceLevelOption
                name={cookingLevel.name}
                icon={cookingLevel.icon}
                isSelected={isSelected}
              />
            </TouchableOpacity>
          );
        })}
      </YStack>
    </YStack>
  );
}
