import { useState, useEffect } from "react";

/**
 * This is a temporary custom hook to assist with the dev-testing and debbuging of the TaskProgressScreen.
 * TODO: delete this once we've hooked TaskProgressScreens to the relevant sources for task progress.
 */
export default function useDummyProgress({
  taskTitle,
  completedSubtasks,
  onCompletion,
  errorMessage,
  startProgress,
  isPromptLoading = false,
}) {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentSubtasks, setCurrentSubtasks] = useState([]);

  useEffect(() => {
    if (!startProgress) {
      // Don't start if startProgress is false
      return;
    }

    // Calculate the progress increment per tick
    const increment = 1 / (completedSubtasks.length + 1);

    // Set up an interval only if progress is less than 1
    if (currentProgress < 1) {
      const interval = setInterval(() => {
        if (currentProgress + increment >= 1 && isPromptLoading) {
          // delay completion until actually complete
          return;
        }
        setCurrentProgress((prevProgress) => {
          const nextProgress = prevProgress + increment;

          // Append the next subtask to the currentSubtasks list
          const nextSubtaskIndex = Math.floor(prevProgress / increment);
          if (nextSubtaskIndex < completedSubtasks.length) {
            setCurrentSubtasks((prevSubtasks) => [
              ...prevSubtasks,
              completedSubtasks[nextSubtaskIndex],
            ]);
          }

          // If progress reaches or exceeds 1, call the onCompletion callback
          if (nextProgress >= 1 && onCompletion && !isPromptLoading) {
            setTimeout(() => {
              onCompletion();
            }, 1000);
          }

          return nextProgress > 1 ? 1 : nextProgress;
        });
      }, 12000); // Increment every 12 seconds (assuming it takes at least 1 minute)

      // Clear the interval when progress reaches 1 or the component using the hook is unmounted
      return () => clearInterval(interval);
    }
  }, [currentProgress, completedSubtasks, onCompletion, startProgress]);

  useEffect(() => {
    if (currentProgress >= 1) {
      setCurrentSubtasks((prevSubtasks) => [...prevSubtasks, "Complete!"]);
    }
  }, [currentProgress]);

  return {
    currentProgress,
    currentSubtasks,
    taskTitle,
    errorMessage,
  };
}
