import * as React from "react";
import { useAppSelector } from "../redux/hooks";
import { AppUserToken } from "../types";

export default function useIsAuthenticated() {
  const userTokens: Partial<AppUserToken> = useAppSelector(
    (state) => state.auth
  );

  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  React.useEffect(() => {
    // do we need to validate the user object as well?
    const hasTokens = userTokens?.token && userTokens?.token_type;
    if (hasTokens && !isAuthenticated) {
      setIsAuthenticated(true);
    } else if (!hasTokens && isAuthenticated) {
      setIsAuthenticated(false);
    }
  }, [userTokens]);

  return isAuthenticated;
}
