import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import reduxConstants from "../reduxConstants";

export const INGREDIENTS_DATA_KEY = "foodx_ingredients";

interface IStateProps {
  shoppingList: string[];
}

const initialState: IStateProps = {
  shoppingList: [],
};

const slice = createSlice({
  name: reduxConstants.INGREDIENTS.NAME,
  initialState,
  reducers: {
    addToShoppingList: (
      state,
      { payload: ingredients }: PayloadAction<string[]>
    ) => {
      const ingredientsToAdd = ingredients.filter(
        (ingredient) => !state.shoppingList.includes(ingredient)
      );
      if (!ingredientsToAdd.length) {
        return state;
      }
      state.shoppingList = [...state.shoppingList, ...ingredientsToAdd];
    },
    removeFromShoppingList: (state, { payload: ingredient }: PayloadAction<string>) => {
      if (!state.shoppingList.includes(ingredient)) {
        return state;
      }
      const indexToRemove = state.shoppingList.indexOf(ingredient);
      if (indexToRemove < 0) {
        return state;
      }
      state.shoppingList.splice(indexToRemove, 1);
    },
  },
});

export const { addToShoppingList, removeFromShoppingList } = slice.actions;

export default slice.reducer;
