import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";

// TODO: secure web (localStorage) data using crypto-js
const localStore = {
  /**
   * Securely stores key-value string pairs locally to the device.
   * @param {string} key The key to associate with the stored value.
   * @param {string} value The value to store. Size limit is 2048 bytes.
   * @returns {Promise<void>}
   */
  async set(key: string, value: string) {
    if (Platform.OS === "web") {
      localStorage.setItem(key, value);
    } else {
      await SecureStore.setItemAsync(key, value);
    }
  },
  /**
   * Fetches the stored value associated with the provided key.
   * @param {string} key The key associated with the required stored value.
   * @returns {Promise<string | null>}
   */
  async get(key: string) {
    if (Platform.OS === "web") {
      return localStorage.getItem(key);
    }
    return SecureStore.getItemAsync(key);
  },
  /**
   * Deletes the stored key-value pair associated with the provided key.
   * @param {string} key The key associated with entry to be deleted.
   * @returns {Promise<void>}
   */
  async delete(key: string) {
    if (Platform.OS === "web") {
      return localStorage.removeItem(key);
    }
    return SecureStore.deleteItemAsync(key);
  },
};

export default localStore;
