import { Control, Controller } from "react-hook-form";
import { Input, InputProps, Text, YStack } from "tamagui";

interface IProps {
  controllerProps: { control: Control<any>; name: string };
  inputProps: InputProps;
}
export default function FormInput({ controllerProps, inputProps }: IProps) {
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => {
        const fieldErrorMessage = fieldState.error?.message;
        return (
          <YStack width="100%" gap={4} flex={1}>
            <Input
              borderColor={fieldErrorMessage ? "red" : inputProps.borderColor}
              borderWidth={fieldErrorMessage ? 1 : inputProps.borderWidth}
              {...field}
              {...inputProps}
              onChangeText={(text) => {
                if (inputProps.onChangeText) {
                  inputProps.onChangeText(text);
                }
                field.onChange(text);
              }}
            />
            {!!fieldErrorMessage && (
              <Text fontFamily="Inter, Helvetica, Arial, sans-serif" color="red">{fieldErrorMessage}</Text>
            )}
          </YStack>
        );
      }}
    ></Controller>
  );
}
