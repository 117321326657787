import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import JWTDecode from "jwt-decode";
import { getCurrentTimeInSeconds } from "../../utility/dateFunctions";
import localStore from "../../utility/secureStore";
import reduxConstants from "../reduxConstants";
import { AppUserToken } from "../../types";

export const USER_TOKENS_KEY = "foodx_user_tokens";

async function getUserTokens() {
  try {
    const userTokensString = await localStore.get(USER_TOKENS_KEY);
    if (!userTokensString) {
      throw new Error("Tokens not found");
    }

    const userTokensObject = JSON.parse(userTokensString);

    // check if object is the correct shape
    if (
      typeof userTokensObject?.token !== "string" ||
      typeof userTokensObject?.refresh_token !== "string"
    ) {
      throw new Error("Invalid tokens");
    }

    // check if tokens are valid (can at least be refreshed)
    const refreshTokenExp = JWTDecode(userTokensObject.refresh_token)?.exp;

    if ((refreshTokenExp ?? 0) < getCurrentTimeInSeconds()) {
      throw new Error("Token expired");
    }

    return userTokensObject;
  } catch (error) {
    // TODO: handle errors
    return null;
  }
}

// TODO: to use getUserTokens must figure out how to await result
const initialState = /*getUserTokens() ??*/ {
  token: "",
  token_type: "",
  // refresh_token: "",
};

const slice = createSlice({
  name: reduxConstants.AUTH.NAME,
  initialState,
  reducers: {
    setTokens: (
      state,
      { payload: { token, token_type } }: PayloadAction<AppUserToken>
    ) => {
      state.token = token || "";
      state.token_type = token_type || "";
      localStore.set(USER_TOKENS_KEY, JSON.stringify({ token, token_type }));
    },
    removeTokens: (state) => {
      state.token = "";
      state.token_type = "";
      localStore.delete(USER_TOKENS_KEY);
    },
  },
});

export const { setTokens, removeTokens } = slice.actions;

export default slice.reducer;
