import FullScreenLayout from "../../layouts/FullScreenLayout";
import { UIText } from "../../components";
import { Square, XStack, YStack } from "tamagui";
import IngredientCard from "../../components/ingredients/IngredientsCard";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { ACTIONS, useIngredients } from "../../context/IngredientsContext";
import { useGetShelfQuery } from "../../redux/api/api-slice";
import { useCallback, useEffect, useState } from "react";
import Ionicons from "@expo/vector-icons/Ionicons";

export default function MyShelfScreen() {
  const { navigate } = useNavigation();
  const {
    state: { shelfList },
    dispatch,
  } = useIngredients();

  const { isLoading, isError, isSuccess, data, error, refetch } =
    useGetShelfQuery();

  const shelfIngredients = data ?? [];

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch])
  );

  const onCardPress = (params) => {
    // TODO...
    console.log("Card press", params);
    navigate("IngredientRecipes", params);
  };

  const onGoToAddShelf = () => {
    // TODO...

    navigate("AddShelf");
  };

  const onRemoveIngredient = (ingredient) => {
    dispatch({ type: ACTIONS.MOVE_TO_SHOP, payload: [ingredient] });
  };

  return (
    <FullScreenLayout flex={1} alignItems="start" paddingTop={24} px={16}>
      <XStack
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        marginBottom={20}
        minHeight={25}
      >
        <UIText fontSize={21} fontWeight={500}>
          My Shelf
        </UIText>
        <Square
          backgroundColor="#EAEAEA"
          borderRadius={6}
          padding={6}
          pressStyle={{ opacity: 0.5 }}
          onPress={onGoToAddShelf}
        >
          <Ionicons name="add-outline" size={20} />
        </Square>
      </XStack>
      <UIText color="#4D4C4C" marginBottom={24}>
        All ingredients marked as available
      </UIText>
      <UIText color="#4D4C4C" fontSize={12} marginBottom={16}>
        {shelfIngredients.length} Ingredients in my shelf
      </UIText>
      <YStack width="100%" gap={8}>
        {shelfIngredients
          // .sort((a, b) => (a.title > b.title ? 1 : -1))
          ?.map((ingredient) => {
            // const { name, title, recipes } = ingredient;

            return (
              <IngredientCard
                key={name}
                name={ingredient.name}
                title={ingredient.name}
                // recipes={recipes}
                onCardPress={onCardPress}
                onRemove={() => onRemoveIngredient(ingredient)}
              />
            );
          })}
      </YStack>
    </FullScreenLayout>
  );
}
