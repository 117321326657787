const reduxConstants = {
  AUTH: {
    NAME: "auth" as const,
  },
  API: {
    NAME: "api" as const,
  },
  INGREDIENTS: {
    NAME: "ingredients" as const,
  },
  RECIPE: {
    NAME: "recipe" as const,
  },
  USER: {
    NAME: "user" as const,
  },
};

export default reduxConstants;
