import * as React from "react";
import { useAppDispatch } from "../redux/hooks";
import { AppUser, AppUserToken, UserSchema, UserTokenSchema } from "../types";
import localStore from "../utility/secureStore";
import { USER_TOKENS_KEY, setTokens } from "../redux/features/auth-slice";
import { USER_DATA_KEY, setUser } from "../redux/features/user-slice";
import { useLazyGetUserQuery } from "../redux/api/api-slice";
import { Platform } from "react-native";

export default function useLoadDataFromStore() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [error, setError] = React.useState<Error | null>();
  const dispatch = useAppDispatch();
  const [] = useLazyGetUserQuery();

  React.useEffect(() => {
    const getUserTokensFromStore = async () => {
      const userTokensString = await localStore.get(USER_TOKENS_KEY);
      if (!userTokensString) {
        return;
      }
      const userTokens = JSON.parse(userTokensString);
      const parsedUserTokens = UserTokenSchema.parse(
        userTokens
      ) as AppUserToken;
      dispatch(setTokens(parsedUserTokens));
    };

    const getUserFromStore = async () => {
      const userString = await localStore.get(USER_DATA_KEY);

      if (!userString) {
        return;
      }
      const user = JSON.parse(userString);
      const parsedUser = UserSchema.parse(user) as AppUser;
      dispatch(setUser(parsedUser));
    };

    const loadFromStore = async () => {
      setIsLoading(true);
      try {
        await getUserTokensFromStore();
        if (Platform.OS === "web") {
          await getUserFromStore();
        }
        setError(null);
      } catch (error: unknown) {
        setError(error as Error);
        console.error({ error }); // TODO: remove
      } finally {
        setIsLoading(false);
        setIsDone(true);
      }
    };

    loadFromStore();
  }, []);

  return { isLoading, isDone, error };
}
