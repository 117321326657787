// interface IIngredient {
//   id?: string;
//   name: string;
//   title: string;
//   recipes: number;
// }

const dummyIngredients /*: IIngredient[]*/ = [
  { name: "flour-all", title: "All-purpose flour", recipes: 6 },
  { name: "salt", title: "Salt", recipes: 21 },
  { name: "tomatoes", title: "Tomatoes", recipes: 8 },
  { name: "rice-basmati", title: "Basmati rice", recipes: 9 },
  { name: "onions", title: "Onions", recipes: 23 },
  { name: "lamb-cutlets", title: "Lamb cutlets", recipes: 3 },
  { name: "eggs", title: "Eggs", recipes: 15 },
  { name: "blueberries", title: "Blueberries", recipes: 5 },
  { name: "baking-powder", title: "Baking powder", recipes: 4 },
  { name: "sugar-white", title: "White sugar", recipes: 10 },
  { name: "butter", title: "Butter", recipes: 15 },
  { name: "vanilla-extract", title: "Vanilla extract", recipes: 8 },
  { name: "milk", title: "Milk", recipes: 8 },
];

export default dummyIngredients;
