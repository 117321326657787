import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function fillLeftToLength(
  sourceString,
  fillerString = "",
  totalLength = 0
) {
  if (fillerString.toString().length < 1) {
    return sourceString.toString();
  }
  let filledString = "";
  while (filledString.length < totalLength - sourceString.toString().length) {
    filledString += fillerString;
  }
  filledString += sourceString;
  return filledString;
}

export function debounce(func, delay) {
  let inDebounce: NodeJS.Timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
}

export function objectsAreEqual(
  objA: Record<string, any>,
  objB: Record<string, any>
): boolean {
  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let key of keysA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
}

// TODO: move this somewhere else
export type SuccessResponse = {
  data: any;
};
export type ErrorResponse = {
  error: FetchBaseQueryError | SerializedError;
};
export type ApiResponse = SuccessResponse | ErrorResponse;
export function isErrorResponse(
  response: ApiResponse
): response is ErrorResponse {
  return (response as ErrorResponse).error !== undefined;
}
