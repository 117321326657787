import { Circle, XStack, YStack, TextArea } from "tamagui";
import { UIText } from "../../components";
import Ionicons from "@expo/vector-icons/Ionicons";
import FeatherIcons from "@expo/vector-icons/Feather";
import { useState } from "react";
import {
  useGetShelfQuery,
  useLazyGetShelfQuery,
} from "../../redux/api/api-slice";

export default function AvailableIngredient({ promptData, setGeneratePrompt }) {
  const selectedAvailableIngredients = promptData.availableIngredients;
  const [ingredientsList, setIngredientsList] = useState(
    selectedAvailableIngredients.join("\n")
  );

  const onUpdate = (newAvailableIngredients) => {
    setIngredientsList(newAvailableIngredients);
    setGeneratePrompt((prev) => ({
      ...prev,
      availableIngredients: newAvailableIngredients
        ?.split("\n")
        .filter(Boolean),
    }));
  };

  const [shelfQuery, { isLoading, isError, isSuccess, data, error }] =
    useLazyGetShelfQuery();

  const [shelfIngredients, setShelfIngredients] = useState([]);
  const loadShelf = async () => {
    try {
      const response = await shelfQuery();

      const names = response.data.map((item) => item.name);

      const newLineSeparatedNames = names.join("\n");

      setIngredientsList(newLineSeparatedNames);

      setGeneratePrompt((prev) => ({
        ...prev,
        availableIngredients: newLineSeparatedNames
          ?.split("\n")
          .filter(Boolean),
      }));
    } catch (error) {
      // Handle errors, e.g., display an error message
      console.error("Error fetching shelf information:", error);
    }
  };

  return (
    <YStack gap={12} height="100%" flexShrink={1} flexGrow={1}>
      <UIText fontSize={21}>What ingredients do you have available?</UIText>
      <YStack gap={6} paddingBottom={18}>
        <UIText color="#4D4C4C">
          Please add 1 ingredient name per text line.
        </UIText>
        <XStack alignItems="flex-end" gap={8}>
          <Ionicons name="mic-outline" color="#B6B6B6" size={16} />
          <UIText color="#4D4C4C" fontStyle="italic">
            Input via voice
          </UIText>
        </XStack>
      </YStack>
      <YStack gap={8}>
        <UIText fontSize={11} fontWeightt={700}>
          AVAILABLE INGREDIENTS
        </UIText>
        <TextArea
          backgroundColor="#F5F5F5"
          borderWidth={0}
          borderRadius={4}
          minHeight={180}
          padding={12}
          keyboardType="default"
          value={ingredientsList}
          onChangeText={onUpdate}
          placeholder={"e.g.\nPasta\nTomatoes"}
          placeholderTextColor="#999999"
          focusStyle={{
            outlineColor: "black",
            outlineWidth: 0,
          }}
        />
      </YStack>
      <XStack
        justifyContent="center"
        alignItems="center"
        borderRadius={100}
        py={24}
        pressStyle={{ opacity: 0.5 }}
        // TODO: allow for voice input
        onPress={() => console.log("TODO...")}
      >
        <Circle
          borderColor="black"
          borderWidth={1.5}
          size={64}
          justifyContent="center"
          alignItems="center"
        >
          <Ionicons name="mic-outline" size={36} color="black" />
        </Circle>
      </XStack>
      <YStack flexGrow={1} justifyContent="flex-end">
        <XStack
          justifyContent="center"
          alignItems="center"
          py={8}
          borderWidth={1}
          borderRadius={4}
          gap={8}
          pressStyle={{ opacity: 0.5 }}
          // TODO: get ingredients from my shelf
          onPress={() => loadShelf()}
        >
          <FeatherIcons name="archive" size={20} />
          <UIText fontSize={15} fontWeight={600}>
            My Shelf Ingredients
          </UIText>
        </XStack>
      </YStack>
    </YStack>
  );
}
